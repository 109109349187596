/**
 * Process message of type web text
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */


/**
 * Process message of type web text
 *
 * @param {string} message Message to process
 *
 * @return {object} Processed message object
 */
export const processWebText = (message) => {
    var urlWithoutAnchorRegEx = new RegExp('(?:^|\s)(https?:\/\/[^\\s]+)', 'gmi');

    // Replace URLs
    if (urlWithoutAnchorRegEx.test(message)) {
        message = message.replace(urlWithoutAnchorRegEx, function(url) {
            return '<a href="' + url + '" target="_blank">' + url + '</a>';
        });
    }

    // Replace line breaks
    message = message.replace(/(?:\r\n|\r|\n)/g, '<br />');

    // Replace bold markup first, order matters as markup overlaps
    message = message.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

    // Replace italic markup
    message = message.replace(/\*(.*?)\*/g, '<em>$1</em>');

    return message;
};
