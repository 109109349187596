<!--
/**
 * Points template
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { computed } from 'vue';
    import { t } from '?/Composable/Translation';
    import store from '?/Stores';
    import { station as stationData, point as pointData } from '?/ProjectData';
    import { getAssetPathForPoints, getAssetPathForStations } from '?/Composable/Tools';

    // Functions
    const stationTitle = (station) => {
        let data = stationData
            .filter((item) => {
                return (item.id == station.stationId);
            });

        return (data.length)
            ? (station.done && data.hasOwnProperty('titleDone'))
                ? data[0].titleDone
                : (data[0].title)
                    ? data[0].title
                    : station.name
            : '';
    };

    const stationScore = (station) => {
        let stars = [];

        for (var i = 1; i <= station.maxPoints; i++) {
            stars.push(i <= station.points)
        }

        return stars;
    };

    const getCrestCount = () => {
        let stories = {
            ...store.getters.stories,
            ...store.getters.processedStations('story', null, true)
        };

        const storiesPlayed = stories = Object.values(stories)
            .filter((item) => {
                return item.done;
            });

        return storiesPlayed.length;
    };

    // Data
    const stations = computed(() => {
        let stations = store.getters.processedStations();

        // Only show played stations
        stations = stations
            .filter((item) => {
                return (item.played) ? item.played : item.done;
            });

        return stations;
    });

    const crestCount = computed(() => {
        return getCrestCount();
    });

    const crestDescription = computed(() => {
        const data = pointData
            .find((item) => {
                return (item.count == getCrestCount());
            });

        if (data) {
            return data.text;
        }

        return null;
    });

    const crestImage = computed(() => {
        const data = pointData
            .find((item) => {
                return (item.count == getCrestCount());
            });

        if (data) {
            return getAssetPathForPoints() + '/' + data.image;
        }

        return null;
    });

    const pointsText = computed(() => {
        let points = 0;
        let text = t('point.no-points');
        let stations = store.getters.processedStations();

        // Only count played stations
        stations = stations
            .filter((item) => {
                return (item.played) ? item.played : item.done;
            });

        stations.forEach((station) => {
            points += station.points;
        });

        if (points > 0) {
            text = t('point.points', points);
        }

        return text;
    });
</script>

<template>
    <div id="points" class="points-wrapper" v-if="stations">
        <div class="points-crest" :class="'crest-status-' + crestCount">
            <figure class="crest-figure">
                <img class="crest-image"
                     v-if="crestImage"
                     :src="crestImage" />
                <figcaption class="crest-description"
                            v-if="crestDescription">
                    {{ crestDescription }}
                </figcaption>
            </figure>
        </div>

        <div class="points" v-html="pointsText"></div>

        <h3 class="points-station-headline" v-if="stations.length">
            {{ $t('point.finished-stations')}}
        </h3>

        <template v-for="station in stations">
            <div class="points-station"
                 :id="'points-station-' + station.stationId"
                 :class="'points-group-' + station.groupName">
                <router-link :to="'/webapp/station/' + station.stationId">
                    <figure class="points-station-image">
                        <span class="points-station-image-wrapper"
                              :style="'background-image: url(\'' + getAssetPathForStations() + '/station-' + station.stationId + '.jpg\');'">
                            <img :src="getAssetPathForStations() + '/station-' + station.stationId + '.jpg'"
                                 alt="" />
                        </span>
                        <figcaption class="points-station-description">
                            <div class="points-station-name">{{ stationTitle(station) }}</div>
                            <div class="points-station-score-wrapper">
                                <span class="points-station-score"
                                      v-for="score in stationScore(station)"
                                      :class="{filled: score}"
                                      v-html="score ? '<span class=\'point\'>★</span>': '<span class=\'point\'>☆</span>'">
                                </span>
                            </div>
                        </figcaption>
                    </figure>
                </router-link>
            </div>
        </template>
    </div>
    <div id="points" class="no-points" v-else>
        <p>
            {{ $t('point.no-points-call-to-action') }}
        </p>
    </div>
</template>
