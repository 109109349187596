<!--
/**
 * Audio message template
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { reactive, defineProps, computed } from 'vue';
    import { getAssetPathForMessages } from '?/Composable/Tools';
    import AudioPlayer from '?/Component/Library/AudioPlayer';
    import AudioEventModel from '?/Model/AudioEventModel';
    import repository from '?/Composable/Repository';
    import constants from '?/Constants';

    // Data
    const props = defineProps({
        message: {
            type: Object,
            required: true
        }
    });

    const state = reactive({
        baseAssetPath: getAssetPathForMessages(),
        prePlayedLabel: (constants.showPrePlayedLabelInMessageAudio && props.message.originator)
            ? props.message.originator
            : null
    });

    const events = computed(() => {
        let e = [];

        // Medium seen event
        if (props.message.seenRatio) {
            let seenThreshold = parseFloat(props.message.seenRatio);
            let customEvent = new AudioEventModel();

            customEvent.eventName = 'timeupdate';
            customEvent.stepInPercent = (seenThreshold * 100);
            customEvent.repeatable = false;
            customEvent.callback = (e) => {
                repository.sendMediumProgressToStation(
                    props.message.stationId,
                    props.message.contentRef,
                    seenThreshold
                );
            };

            e.push(customEvent);
        }

        return e;
    });
</script>

<template>
    <AudioPlayer :src="state.baseAssetPath + '/' + message.content"
                 :events="events"
                 :prePlayedLabel="state.prePlayedLabel" />
</template>
