/**
 * Sanitize user input
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */

import router from '?/Router/Webapp';


/**
 * Sanitize user input
 *
 * @param {string} s String to sanitize
 *
 * @return {string} Sanitized string
 */
export const  sanitizeUserInput = (s) => {
    s = s.trim(s);
    s = s.replace(/\s+/g, ' ');
    s = s.replace(/\//g, '');

    return s;
};
