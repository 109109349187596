<!--
/**
 * Single hostel template
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { computed } from 'vue';
    import { useRoute } from 'vue-router';
    import config from '?/Config';
    import store from '?/Stores';
    import { hostel as currentStationData } from '?/ProjectData';
    import { getAssetPath, formatDistance } from '?/Composable/Tools';

    // Initialization
    const route = useRoute();

    // Data
    const station = computed(() => {
        return currentStationData.find((data) => {
            return data.id == route.params.id;
        });
    });
</script>

<template>
    <div id="station-detail"
         class="station-detail hostel"
         v-if="station">
        <div class="station"
             :id="'station-' + station.id">
            <figure class="station-image">
                <span class="station-image-wrapper"
                      :style="'background-image: url(\'' + getAssetPath() + '/hostels/hostel-' + station.id + '.jpg\');'">
                    <img :src="getAssetPath() + '/hostels/hostel-' + station.id + '.jpg'"
                         alt="" />
                </span>
                <figcaption>
                    <h2 class="station-name"
                        v-html="station.longName"></h2>
                    <div class="station-description"
                         v-html="station.longDesc"></div>
                    <div class="station-link">
                        <a :href="station.link" class="button" target="_blank">Zur Website</a>
                    </div>
                    <div class="station-distance" v-if="store.getters.geolocation && station.longitude != 0 && station.latitude != 0">
                        {{ formatDistance(station.distance) }}
                    </div>
                    <div class="station-group" :class="'station-group-Jugendherbergen'">
                        Jugendherbergen
                    </div>
                </figcaption>
            </figure>
        </div>
    </div>
    <div id="station" class="station-wrapper hostel" v-else>
        {{ $t('station.unavailable') }}
    </div>
</template>
