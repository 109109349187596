<!--
/**
 * Station list template for email
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { computed } from 'vue';
    import { useRoute } from 'vue-router';
    import store from '?/Stores';
    import { email as emailData } from '?/ProjectData';
    import { getAssetPath } from '?/Composable/Tools';
    import { t } from '?/Composable/Translation';

    // Initialization
    const route = useRoute();

    // Data
    const slug = computed(() => {
        return route.meta.slug;
    });

     const stations = computed(() => {
         let stations = store.getters.processedStations(route.meta.slug);

         // Filter by secret state
         stations = stations
             .filter((item) => {
                 return (
                     !item.secret
                 );
             });

         // Sort by id (descending)
         stations = stations
             .sort((itemA, itemB) => {
                 return itemB.stationId - itemA.stationId;
             });

         return stations;
    });

    // Functions
    const data = (id) => {
        return emailData.find((data) => {
            return data.id == id;
        });
    };
</script>

<template>
    <div id="station-email-list" class="station-email-list" v-if="stations.length">
        <template v-for="station in stations">
            <div class="email"
                 :id="'email-' + station.stationId">
                 <router-link :to="'/webapp/' + slug + '/' + station.stationId">
                    <figure class="email-author-image">
                        <span class="email-author-image-wrapper"
                              :style="'background-image: url(\'' + getAssetPath() + '/' + slug + '/author-' + data(station.stationId).author + '.png\');'">
                            <img :src="getAssetPath() + '/' + slug + '/author-' + data(station.stationId).author + '.png'"
                                 alt="" />
                        </span>
                        <figcaption>
                            <h3 class="email-author"
                                v-html="t('email.author-' + data(station.stationId).author)"></h3>
                            <div class="email-subject"
                                 v-html="data(station.stationId).subject"></div>
                        </figcaption>
                    </figure>
                </router-link>
            </div>
        </template>
    </div>
    <div id="station-email-list" class="station-email-list" v-else>
        {{ $t('email.no-entries') }}
    </div>
</template>

