<!--
/**
 * Webcontent recommendation message template
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { defineProps, markRaw } from 'vue';
    import { checkWebContentType } from '?/Composable/Tools';
    import Audio from '?/Component/Webapp/MessageTypes/Raw/Audio';
    import Image from '?/Component/Webapp/MessageTypes/Raw/Image';
    import Url from '?/Component/Webapp/MessageTypes/Raw/Url';
    import Video from '?/Component/Webapp/MessageTypes/Raw/Video';
    import Text from '?/Component/Webapp/MessageTypes/Raw/Text';
    import Recommendation from '?/Component/Webapp/MessageTypes/Raw/Recommendation';

    // Data
    const props = defineProps({
        message: {
            type: Object,
            required: true
        }
    });

    // Functions
    function component (type) {
        let component = null;

        switch(type) {
            case 'audio':
                component = markRaw(Audio);

                break;
            case 'image':
                component = markRaw(Image);

                break;
            case 'url':
                component = markRaw(Url);

                break;
            case 'video':
                component = markRaw(Video);

                break;
            default:
                component = markRaw(Text);
        };

        return component;
    }
</script>

<template>
    <div class="message-content-wrapper webcontent-recommendation">
        <component :is="component(checkWebContentType(message.content))"
                   :message="message"></component>
        <component :is="Recommendation"
                   :message="message"></component>
    </div>
</template>
