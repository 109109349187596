<!--
/**
 * Image message template
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { reactive, defineProps } from 'vue';
    import emitter from '?/Composable/Events/Emitter';
    import { getAssetPathForMessages } from '?/Composable/Tools';
    import ImageModal from '?/Component/ModalContent/Image';

    // Data
    const props = defineProps({
        message: {
            type: Object,
            required: true
        }
    });

    const state = reactive({
        baseAssetPath: getAssetPathForMessages()
    });

    // Functions
    function openModal (src) {
        // @event activate-modal
        emitter.emit('activate-modal', {
            component: ImageModal,
            data: {
                image: '<img src=" ' + src + '" />',
                zoom: true
            }
        });
    }
</script>

<template>
    <template v-if="message.feature == 'zoom'">
        <a @click.prevent="openModal(state.baseAssetPath + '/' + message.content)"
           :href="state.baseAssetPath + '/' + message.content"
           class="zoom">
            <img :src="state.baseAssetPath + '/' + message.content" />
        </a>
    </template>
    <template v-else>
        <img :src="state.baseAssetPath + '/' + message.content" />
    </template>
</template>
