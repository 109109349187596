/**
 * Store for `playerstatus` data from CIMMS API
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */

import { toRaw } from 'vue';
import constants from '?/Constants';


// Initial state
const state = () => ({
    activeStations: [],
    currentStationId: null,
    currentStation: null,
    currentStoryId: null,
    currentStory: null,
    stations: {},
    stories: {},
    totalPoints: null,
    stationList: [],
    unseenStationCount: {},
    hasTeam: null
})

// Getters
const getters = {
    activeStations (state) {
        return state.activeStations;
    },

    isStationActive: (state) => (stationId) => {
        return (stationId)
            ? state.activeStations.includes(stationId)
            : false;
    },

    currentStationId (state) {
        return state.currentStationId;
    },

    currentStation (state) {
        return Object.values(state.stations)
            .find((data) => {
                return data.stationId == state.currentStationId;
            });
    },

    currentStoryId (state) {
        return state.currentStoryId;
    },

    currentStory (state) {
        return Object.values(state.stories)
            .find((data) => {
                return data.storyId == state.currentStoryId;
            });
    },

    stations (state) {
        return state.stations;
    },

    processedStations: (state) => (view, stations, ignoreVisibility) => {
        view = view || constants.defaultStationViewName;
        stations = stations || state.stations;
        ignoreVisibility = ignoreVisibility || false;

        // Only show stations that are live and visible and sort alphabetically
        return Object.values(stations)
            .filter((item) => {
                return (
                    item.live &&
                    (ignoreVisibility || item.visible) &&
                    item.view === view
                );
            })
            .sort((a, b) => (a.name > b.name)
                ? 1
                : ((b.name > a.name) ? -1 : 0)
            );
    },

    station: (state) => (stationId) => {
        return state.stations[stationId];
    },

    stories (state) {
        return state.stories || {};
    },

    totalPoints (state) {
        return state.totalPoints;
    },

    stationsWithPointsCount (state) {
        return Object.values(state.stations)
            .filter((item) => {
                return (
                    (item.played || item.done) &&
                    item.points > 0
                );
            }).length;
    },

    stationList (state) {
        return state.stationList;
    },

    unseenStationCount (state) {
        return state.unseenStationCount;
    },

    unseenStationCountForStationType: (state) => (stationType) => {
        return (state.unseenStationCount.hasOwnProperty(stationType))
            ? state.unseenStationCount[stationType]
            : null;
    },

    hasTeam (state) {
        return state.hasTeam;
    }
};

// Actions
const actions = {
    deleteDataforStation (context, stationId) {
        stationId = (parseInt(stationId, 10) > 0)
            ? stationId
            : null;

        context.commit('deleteMessagesForStation', stationId);
        context.commit('deleteStationFromAnswerExpected', stationId);
        context.commit('deleteStationFromAnswerRejected', stationId);
        context.commit('deleteStationFromButtonInputExpected', stationId);
        context.commit('deleteButtonsFromStation', stationId);
        context.commit('lastActiveStationId', null);
    },

    changeCurrentStationId (context, stationId) {
        stationId = (parseInt(stationId, 10) > 0)
            ? stationId
            : null;

        // Delete persisted data for previously activated station
        if (
            (!stationId || stationId !== context.getters.currentStationId) &&
            !context.getters.lastActiveStationId
        ) {
            context.dispatch('deleteDataforStation', context.getters.currentStationId);
        }

        // Save current station id
        if (stationId) {
            context.commit('lastActiveStationId', stationId);
        }

        context.commit('currentStationId', stationId);
    },

    fillStationList (context, stations) {
        stations = stations || context.getters.stations;

        const stationList = toRaw(context.getters.stationList);

        if (stations) {
            Object.values(stations).forEach((station) => {
                if (!stationList.includes(station.view)) {
                    stationList.push(station.view);
                }
            });

            context.commit('stationList', stationList);
        }
    },

    checkUnseenStationCount (context, stations) {
        context.dispatch('fillStationList', stations);

        const isFirstPlayerstatusEvent = (Object.entries(context.getters.stations).length);
        const stationList = toRaw(context.getters.stationList);

        // Diff old and new station list per station type
        if (stationList && isFirstPlayerstatusEvent) {
            stationList.forEach((stationType) => {
                // Skip if an unseen count is set, because of unreliable `playerstatus` events.
                // @TODO: Fix if station visibility events are available
                if (context.getters.unseenStationCountForStationType(stationType)) {
                    return;
                }

                let oldStations = context.getters.processedStations(stationType);
                let newStations = context.getters.processedStations(stationType, stations);
                let filteredStations = newStations
                    .filter((newStation) => {
                        // Check if station is available in old list
                        return (!oldStations.length || !oldStations
                            .find((oldStation) => {
                                return oldStation.stationId === newStation.stationId;
                            }));
                    });
                let count = filteredStations.length;

                if (count > 0) {
                    context.commit('addUnseenStationCount', [stationType, '!']);
                } else {
                    context.commit('deleteUnseenStationCount', stationType);
                }
            });
        }
    }
};

// Mutations
const mutations = {
    activeStations (state, activeStations) {
        state.activeStations = activeStations;
    },

    currentStationId (state, currentStationId) {
        state.currentStationId = (parseInt(currentStationId, 10) > 0)
            ? currentStationId
            : null;
    },

    currentStoryId (state, currentStoryId) {
        state.currentStoryId = (parseInt(currentStoryId, 10) > 0)
        ? currentStoryId
        : null;
    },

    station (state, data) {
        let stationId = data[0];
        let station = data[1];

        state.stations[stationId] = station;
    },

    stations (state, stations) {
        state.stations = stations;
    },

    stories (state, stories) {
        state.stories = stories;
    },

    totalPoints (state, totalPoints) {
        state.totalPoints = totalPoints;
    },

    stationList (state, stationList) {
        state.stationList = stationList;
    },

    unseenStationCount (state, unseenStationCount) {
        state.unseenStationCount = unseenStationCount;
    },

    addUnseenStationCount (state, data) {
        state.unseenStationCount[data[0]] = data[1];
    },

    deleteUnseenStationCount (state, stationType) {
        delete state.unseenStationCount[stationType];
    },

    hasTeam (state, hasTeam) {
        state.hasTeam = hasTeam;
    }
};

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
};
