/**
 * Get asset path
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */

import config from '?/Config';


/**
 * Get asset path
 *
 * @return {string} Asset path
 */
export const getAssetPath = () => {
    const baseUrl = config.baseUrl || '/';
    return baseUrl + 'assets/webapp-data';
};
