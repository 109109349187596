/**
 * Scroll to bottom
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */

import router from '?/Router/Webapp';


/**
 * Scroll to bottom
 *
 * @param {string} scope Page where scroll action should happen
 *
 * @return {void}
 */
export const scrollToBottom = (scope) => {
    if (scope && router.currentRoute.value.meta.scope !== scope) {
        return;
    }

    let body = document.body,
        html = document.documentElement,
        height;

    setTimeout(() => {
        height = Math.max(
            body.scrollHeight,
            body.offsetHeight,
            html.clientHeight,
            html.scrollHeight,
            html.offsetHeight
        );

        window.scrollTo({
            top: height,
            left: 0,
            behavior: 'smooth'
        });
    }, 10);
};
