<!--
/**
 * Modal component for lost password
 *
 * Usage:
 *
 * ```
 * import LostPasswordModal from '?/Component/ModalContent/LostPassword';
 *
 * // @event activate-modal
 * emitter.emit('activate-modal', {
 *     component: LostPasswordModal
 * });
 * ```
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { reactive, computed, inject } from 'vue';
    import { useVuelidate } from '@vuelidate/core';
    import { required } from '@vuelidate/validators';
    import filter from '?/Composable/Filter';
    import repository from '?/Composable/Repository';
    import emitter from '?/Composable/Events/Emitter';

    // Data
    const state = reactive({
        isLoading: false,
        apiErrors: [],
        identifier: '',
        submitted: false,
    });

    // Functions
    const formSubmit = async () => {
        state.isLoading = true;
        const validation = await v$._value.$validate();

        if (!validation) {
            state.isLoading = false;
            return;
        }

        let identifier = filter.phoneNumber(state.identifier);

        repository.requestPasswordResetPhone(identifier)
            .then((response) => {
                // @event password-reset-sent
                emitter.emit('password-reset-sent', identifier);

                state.submitted = true;

                return response;
            })
            .catch((error) => {
                state.apiErrors = [error];

                return error;
            })
            .finally(() => {
                state.isLoading = false;
            });
    };

    // Validation
    const rules = computed(() => ({
        identifier: {
            required
        }
    }));
    const v$ = useVuelidate(rules, state);
</script>

<template>
    <form id="lost-password-form"
              class="lost-password-form"
              method="post"
              action="./"
              autocomplete="off"
              v-on:submit.prevent.capture="formSubmit"
              v-if="!state.submitted">

        <fieldset class="lost-password-form-credentials">
            <legend>{{ $t('account.credentials')}}</legend>

            <p class="error" v-if="state.apiErrors.length">
                <span class="input-errors" v-for="error of state.apiErrors" :key="error.code">
                    <span class="error-msg">{{ $t('apiError.' + error.code) }}</span>
                </span>
            </p>

            <p class="lost-password-form-credentials-identifier"
               :class="{ error: v$.identifier.$errors.length }">
                <span class="input-errors" v-for="error of v$.identifier.$errors" :key="error.$uid">
                    <span class="error-msg">{{ $t('vuelidate.' + error.$message) }}</span>
                </span>
                <label for="lost-password-identifier">{{ $t('account.phone-number')}}</label>
                <input type="text" id="lost-password-identifier" v-model="state.identifier" :placeholder="$t('account.identifier-placeholder')" />
            </p>

            <p>
                <input type="submit" :value="$t('account.send-link')" :disabled="state.isLoading" />
            </p>
        </fieldset>

    </form>
    <p class="lost-password-message"
       v-if="state.submitted"
       v-html="$t('account.lost-password-reset-successful')"></p>
</template>
