/**
 * Get unseen stories
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */

import store from '?/Stores';
import { story as storyData } from '?/ProjectData';


/**
 * Get unseen stories
 *
 * @return {array} Unseen stories
 */
export const getUnseenStories = () => {
    const totalPoints = store.getters.stationsWithPointsCount;

    let stories = {
        ...store.getters.stories,
        ...store.getters.processedStations('story', null, true)
    };

    stories = Object.values(stories)
        .filter((item) => {
            // Filter by `live` status
            if (!item.live) {
                return false;
            }

            // Filter by points
            const storyId = item.storyId || item.stationId
            const currentStoryData = storyData.find((data) => {
                return data.id == storyId;
            });

            // Filter by `done`
            if (item.done) {
                return false;
            }

            if (currentStoryData) {
                return (currentStoryData.points <= totalPoints);
            }

            return false;
        });

    return stories;
};
