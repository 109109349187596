<!--
/**
 * Webapp end template (delete account)
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import store from '?/Stores';
    import repository from '?/Composable/Repository';
    import { routeTo } from '?/Composable/Tools';
    import { t } from '?/Composable/Translation';
    import emitter from '?/Composable/Events/Emitter';
    import InteractionModal from '?/Component/ModalContent/Interaction';

    // Functions
    const end = () => {
        // @event activate-modal
        emitter.emit('activate-modal', {
            component: InteractionModal,
            title: t('end.label'),
            data: {
                description: t('end.confirmation'),
                confirmationCallback: () => {
                    repository.activateOutro(true)
                        .finally((response) => {
                            repository.logout()
                                .finally((response) => {
                                    localStorage.clear();
                                    window.location.replace('/?account-deleted=1');
                                });
                        });
                },
                cancelationCallback: () => {}
            }
        });
    };
</script>

<template>
    <div class="end"
         v-if="store.getters.isAuthenticated">
        <p v-html="$t('end.description')"></p>
        <button class="end-button"
                @click="end()">
            {{ $t('end.label') }}
        </button>
    </div>
</template>
