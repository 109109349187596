/**
 * Format distance
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */


/**
 * Format distance
 *
 * @param {float} dist Distance to format
 *
 * @return {string} Formatted distance
 */
export const formatDistance = (dist) => {
    if (dist > 1000) {
        return Math.round(dist / 1000) + ' km';
    } else {
        let distInMeters = dist - (dist % 50) + 50;
        return distInMeters + ' m';
    }
};
