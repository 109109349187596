<!--
/**
 * Webapp template for 404 page
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<template>
    <div class="not-found">
        {{ $t('webapp.404-description') }}
    </div>
</template>
