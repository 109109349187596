<!--
/**
 * Station list template for hostels
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { computed } from 'vue';
    import { useRoute } from 'vue-router';
    import store from '?/Stores';
    import { hostel as currentStationData } from '?/ProjectData';
    import { getAssetPath, formatDistance } from '?/Composable/Tools';
    import constants from '?/Constants';

    // Initialization
    const route = useRoute();

    // Data
    const stations = computed(() => {
        let stations = currentStationData;

        // Sort by distance
        if (store.getters.geolocation && constants.allowSortbyDistance) {
            stations = currentStationData
                .sort((itemA, itemB) => {
                    return itemA.distance - itemB.distance;
                });
        }

        return stations;
    });
</script>

<template>
    <div id="station-list" class="station-list" v-if="stations">
        <template v-for="station in stations">
            <div class="station"
                 :id="'station-' + station.id">
                <router-link :to="'/webapp/hostel/' + station.id">
                    <figure class="station-image">
                        <span class="station-image-wrapper"
                              :style="'background-image: url(\'' + getAssetPath() + '/hostels/hostel-' + station.id + '.jpg\');'">
                            <img :src="getAssetPath() + '/hostels/hostel-' + station.id + '.jpg'"
                                 alt="" />
                        </span>
                        <figcaption>
                            <h3 class="station-name"
                                v-html="station.title"></h3>
                            <div class="station-description"
                                 v-html="station.desc"></div>
                            <div class="station-distance" v-if="store.getters.geolocation && station.longitude != 0 && station.latitude != 0">
                                {{ formatDistance(station.distance) }}
                            </div>
                            <div class="station-group" :class="'station-group-Jugendherbergen'">
                                Jugendherbergen
                            </div>
                        </figcaption>
                    </figure>
                </router-link>
            </div>
        </template>
    </div>
    <div id="station-list" class="station-list station-list" v-else>
        {{ $t('station.no-entries') }}
    </div>
</template>
