<!--
/**
 * Modal component for user interaction
 *
 * Usage:
 *
 * ```
 * import InteractionModal from '?/Component/ModalContent/Interaction';
 *
 * // @event activate-modal
 * emitter.emit('activate-modal', {
 *     component: InteractionModal,
 *     title: 'Test Title',
 *     defaultCloseButton: false,
 *     data: {
 *         description: 'Lorem Lorem ipsum dolor sit amet.',
 *         confirmationCallback: function () {
 *             console.log('Confirmed');
 *         },
 *         cancelationCallback: function () {
 *             console.log('Canceled');
 *         }
 *     }
 * });
 * ```
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { defineProps, inject } from "vue";
    import emitter from '?/Composable/Events/Emitter';

    // Get parent data
    const props = defineProps({
        data: {
            type: Object,
            required: true
          }
    });

    // Define confirmation callback
    const confirmationCallback = function () {
        if (typeof props.data.confirmationCallback === 'function') {
            props.data.confirmationCallback();
        }

        // @event close-modal
        emitter.emit('close-modal');
    };

    // Define cancelation callback
    const cancelationCallback = function () {
        if (typeof props.data.cancelationCallback === 'function') {
            props.data.cancelationCallback();
        }

        // @event close-modal
        emitter.emit('close-modal');
    };
</script>

<template>
    <p class="description"
       v-if="props.data.description"
       v-html="props.data.description"></p>

    <div class="interaction">
        <button class="confirmation-button"
                v-on:click="confirmationCallback">
            {{ $t('modal.yes') }}
        </button>
        <button class="cancelation-button"
                v-on:click="cancelationCallback"
                v-if="props.data.cancelationCallback">
            {{ $t('modal.no') }}
        </button>
    </div>
</template>
