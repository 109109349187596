<!--
/**
 * Station list template
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { computed, reactive, markRaw, onMounted } from 'vue';
    import { useRoute } from 'vue-router';
    import store from '?/Stores';
    import Archive from '?/Component/Webapp/StationLists/Archive';
    import Email from '?/Component/Webapp/StationLists/Email';
    import Main from '?/Component/Webapp/StationLists/Main';
    import Scoreboard from '?/Component/Webapp/StationLists/Scoreboard';
    import Station from '?/Component/Webapp/StationLists/Station';
    import Story from '?/Component/Webapp/StationLists/Story';

    // Initialization
    const route = useRoute();

    // Functions
    const component = (type) => {
        let component = null;

        switch(type) {
            case 'archive':
                component = markRaw(Archive);

                break;
            case 'email':
                component = markRaw(Email);

                break;
            case 'main':
                component = markRaw(Main);

                break;
            case 'scoreboard':
                component = markRaw(Scoreboard);

                break;
            case 'story':
                component = markRaw(Story);

                break;
            default:
                component = markRaw(Station);
        };

        // Delete unseen station count
        store.commit('deleteUnseenStationCount', type);

        return component;
    }
</script>

<template>
    <component :is="component(route.meta.slug)"
               :key="route.meta.slug"></component>
</template>
