<!--
/**
 * Wrapper modal component – must be globally present in app
 *
 * Usage:
 *
 * ```
 * <script setup>
 *     import Modal from '?/Component/Modal';
 * </script>
 *
 * <template>
 *     <Modal />
 * </template>
 * ```
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { reactive, inject, markRaw, ref, nextTick } from 'vue';
    import config from '?/Config';
    import emitter from '?/Composable/Events/Emitter';
    import { camelToDashedCase } from '?/Composable/Tools';

    // Data
    const defaultCloseButtonValue = true;

    const state = reactive({
        title: '',
        defaultCloseButton: defaultCloseButtonValue,
        isModalVisible: false,
        component: null,
        data: {},
        previousActiveElement: null
    });

    const titleRef = ref();
    const modalRef = ref();

    // Methods
    const handleKeyBoardInput = (event) => {
        const key = event.key;

        if (key == 'Escape') {
            event.preventDefault();
            close();
        }
    };

    const close = () => {
        modalRef.value.close();
        state.isModalVisible = false;

        if (state.previousActiveElement) {
            state.previousActiveElement.focus();
        }

        if (state.defaultCloseButton) {
            document.removeEventListener(
                'keydown',
                handleKeyBoardInput
            );
        }
    };

    const modalClasses = () => {
        let classes = '';

        classes += (state.defaultCloseButton)
            ? 'modal-with-close-button'
            : 'modal-without-close-button';

        if (state.component) {
            const componentName = state.component.__name || state.component.name;
            classes += ' modal-type-' + camelToDashedCase(componentName);
        }

        return classes;
    };

    // Events
    emitter.on('activate-modal', (data) => {
        if (!data || !data.hasOwnProperty('component')) {
            return;
        }

        state.isModalVisible = true;
        state.component = markRaw(data.component);
        state.title = (data.hasOwnProperty('title')) ? data.title : '';
        state.defaultCloseButton = (data.hasOwnProperty('defaultCloseButton')) ? data.defaultCloseButton : defaultCloseButtonValue;
        state.data = data.data || {};

        if (document.activeElement) {
            state.previousActiveElement = document.activeElement;
        }

        modalRef.value.showModal();

        nextTick(() => {
            titleRef.value.focus();
        });

        if (state.defaultCloseButton) {
            document.addEventListener(
                'keydown',
                handleKeyBoardInput
            );
        }

        if (config.debug) {
            console.log(
                'Modal event `activate-modal`: ',
                'Data: ', data
            );
        }
    });

    emitter.on('close-modal', () => {
        close();

        if (config.debug) {
            console.log(
                'Modal event `close-modal`: '
            );
        }
    });
</script>

<template>
    <transition name="modal-fade">
        <div class="modal-wrapper"
             :class="{ 'modal-active': state.isModalVisible }">
            <div class="modal-backdrop">
                  <dialog class="modal"
                          :class="modalClasses()"
                          ref="modalRef"
                          aria-labelledby="modal-title"
                          aria-describedby="modal-description">

                    <header class="modal-header"
                            id="modal-title">
                        <div slot="header"
                              v-html="state.title"
                              tabindex="0"
                              ref="titleRef"></div>
                        <button type="button"
                                class="modal-close"
                                v-if="state.defaultCloseButton"
                                @click="close"
                                :aria-label="$t('modal.close')">
                            {{ $t('modal.close') }}
                        </button>
                    </header>

                    <section class="modal-body"
                             id="modal-description">
                        <slot name="content">
                            <component :is="state.component"
                                       :data="state.data"></component>
                        </slot>
                    </section>
                </dialog>
            </div>
        </div>
    </transition>
</template>

<style>
    .modal-wrapper {

        display: none;
        visibility: hidden;

    }

    .modal-wrapper.modal-active {

        display: block;
        visibility: visible;

    }

    .modal-backdrop {

        display: flex;
        justify-content: center;
        align-items: center;

        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1000;

        overscroll-behavior: contain;

    }

    dialog.modal {

        margin: auto;

    }

    .modal-fade-enter,
    .modal-fade-leave-to {

        opacity: 0;

    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {

        transition: opacity .5s ease;

    }
</style>
