<!--
/**
 * Station list template for station
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { computed } from 'vue';
    import { useRoute } from 'vue-router';
    import store from '?/Stores';
    import { all as projectData } from '?/ProjectData';
    import { getAssetPathForStations, formatDistance } from '?/Composable/Tools';
    import constants from '?/Constants';

    // Initialization
    const route = useRoute();

    // Data
    const stations = computed(() => {
        let stations = store.getters.processedStations(route.meta.slug);

        // Don't show played stations
        stations = stations
            .filter((item) => {
                return (
                    !item.done &&
                    !item.secret
                );
            });

        // Sort by distance
        if (store.getters.geolocation && constants.allowSortbyDistance) {
            stations = stations
                .sort((itemA, itemB) => {
                    return itemA.distance - itemB.distance;
                });
        }

        // Sort by active status (active on top)
        if (store.getters.currentStationId) {
            stations = stations
                .sort((itemA, itemB) => {
                    return (itemB.stationId == store.getters.currentStationId)
                        ? 1
                        : 0;
                });
        }

        return stations;
    });

    const currentStationData = projectData[route.meta.slug];

    // Functions
    const stationTitle = (station) => {
        let data = currentStationData
            .filter((item) => {
                return (item.id == station.stationId);
            });

        return (data.length)
            ? (station.done && data[0].hasOwnProperty('titleDone'))
                ? data[0].titleDone
                : (data[0].title)
                    ? data[0].title
                    : station.name
            : '';
    };

    const stationDescription = (station) => {
        let data = currentStationData
            .filter((item) => {
                return (item.id == station.stationId);
            });

        return (data.length)
            ? (station.done && data[0].hasOwnProperty('descDone'))
                ? data[0].descDone
                : data[0].desc
            : '';
    };

    const stationImagePath = (station) => {
        let data = currentStationData
            .filter((item) => {
                return (item.id == station.stationId);
            });

        return (data[0].hasOwnProperty('imagePath'))
            ? data[0].imagePath
            : getAssetPathForStations() + '/station-' + station.stationId + '.jpg';
    };

    const isActive = (id) => {
        return (
            store.getters.currentStationId == id
        );
    };
</script>

<template>
    <div id="station-list" class="station-list" v-if="stations">
        <template v-for="station in stations">
            <div class="station"
                 :id="'station-' + station.stationId"
                 :class="{'station-active': isActive(station.stationId)}">
                <router-link :to="'/webapp/' + $route.meta.slug + '/' + station.stationId">
                    <figure class="station-image">
                        <span class="station-image-wrapper"
                              :style="'background-image: url(\'' + stationImagePath(station) + '\');'">
                            <img :src="stationImagePath(station)"
                                 alt="" />
                        </span>
                        <figcaption>
                            <h3 class="station-name"
                                v-html="stationTitle(station)"></h3>
                            <div class="station-description"
                                 v-html="stationDescription(station)"></div>
                            <div class="station-distance" v-if="store.getters.geolocation && station.longitude != 0 && station.latitude != 0">
                                {{ formatDistance(station.distance) }}
                            </div>
                            <div class="station-group" :class="'station-group-' + station.groupName">
                                {{ station.groupName }}
                            </div>
                            <div class="station-notification"
                                  v-if="store.getters.unreadMessagesforStation(station.stationId).length"
                                  :aria-label="$t('station.unread-messages')">
                                {{ store.getters.unreadMessagesforStation(station.stationId).length }}
                            </div>
                        </figcaption>
                    </figure>
                </router-link>
            </div>
        </template>
    </div>
    <div id="station-list" class="station-list station-list" v-else>
        {{ $t('station.no-entries') }}
    </div>
</template>
