/**
 * Filter collection
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */


/*
 * Basic filtering of user input
 *
 * @param {string} value User input
 *
 * @rturn {string}
 */
const baseUserInputFilter = function (value) {
    return value
        // Remove leading and trailing whitespaces
        .replaceAll(/^\s+|\s+$/g, '');
};

export default {

    /*
     * Normalize phone numbers
     *
     * @param {string} value Phone number
     *
     * @return {string}
     */
    phoneNumber (value) {
        return baseUserInputFilter(value)
            // International std format (without german short version)
            .replaceAll(/\+/g, '00')
            .replaceAll(/[^0-9]/g, '');
    },

    /*
     * Normalize passwords
     *
     * @param {string} value Password
     *
     * @return {string}
     */
    password (value) {
        return baseUserInputFilter(value);
    },

    /*
     * Normalize names
     *
     * @param {string} value Name
     *
     * @return {string}
     */
    name (value) {
        return baseUserInputFilter(value);
    },

    /*
     * Normalize team code
     *
     * @param {string} value Name
     *
     * @return {string}
     */
    teamCode (value) {
        return baseUserInputFilter(value);
    },

    /*
     * Normalize auth code
     *
     * @param {string} value Name
     *
     * @return {string}
     */
    authCode (value) {
        return baseUserInputFilter(value);
    }

};
