/**
 * Store for settings
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */

import { defaultLocale } from '?/Languages';
import emitter from '?/Composable/Events/Emitter';

// Initial state
const state = () => ({
    language: defaultLocale,
    authenticated: null,
    answerExpected: [],
    answerRejected: [],
    buttonInputExpected: [],
    buttons: [],
    unseenNews: null,
    lastActiveStationId: null
});

// getters
const getters = {
    language (state) {
        return state.language;
    },

    isAuthenticated (state) {
        return state.authenticated;
    },

    answerExpected (state) {
        return state.answerExpected.length;
    },

    answerExpectedforStation: (state) => (stationId) => {
        return (state.answerExpected.indexOf(stationId) > -1);
    },

    answerRejected (state) {
        return state.answerRejected.length;
    },

    answerRejectedforStation: (state) => (stationId) => {
        return (state.answerRejected.indexOf(stationId) > -1);
    },

    buttonInputExpected (state) {
        return (state.buttonInputExpected.indexOf(stationId) > -1);
    },

    buttonInputExpectedForStation: (state) => (stationId) => {
        return (state.buttonInputExpected.indexOf(stationId) > -1);
    },

    buttons (state) {
        return state.buttons;
    },

    buttonsForStation: (state) => (stationId) => {
        return state.buttons
            .filter((item) => {
                return (item.stationId == stationId);
            })
    },

    unseenNews (state) {
        return state.unseenNews;
    },

    lastActiveStationId (state) {
        return state.lastActiveStationId;
    }
};

// actions
const actions = {
    answerRejectedforStationDelayed (context, data) {
        const delay = 10;

        setTimeout(() => {
            context.commit('addStationToAnswerRejected', data);

            // @event answer-rejected-for-station
            emitter.emit('answer-rejected-for-station', data);
        }, delay);
    },

    resetButtonsforStation (context, stationId) {
        context.commit('deleteStationFromButtonInputExpected', stationId);
        context.commit('deleteButtonsFromStation', stationId);
    },
};

// mutations
const mutations = {
    language (state, language) {
        state.language = language;
    },

    authenticated (state, authenticated) {
        state.authenticated = authenticated;
    },

    addStationToAnswerExpected (state, stationId) {
        state.answerExpected.push(stationId);
    },

    deleteStationFromAnswerExpected (state, stationId) {
        const index = state.answerExpected.indexOf(stationId);

        if (index > -1) {
            state.answerExpected.splice(index, 1);
        }
    },

    addStationToAnswerRejected (state, stationId) {
        state.answerRejected.push(stationId);
    },

    deleteStationFromAnswerRejected (state, stationId) {
        const index = state.answerRejected.indexOf(stationId);

        if (index > -1) {
            state.answerRejected.splice(index, 1);
        }
    },

    addStationToButtonInputExpected (state, stationId) {
        state.buttonInputExpected.push(stationId);
    },

    deleteStationFromButtonInputExpected (state, stationId) {
        const index = state.buttonInputExpected.indexOf(stationId);

        if (index > -1) {
            state.buttonInputExpected.splice(index, 1);
        }
    },

    addButtonToStation (state, button) {
        state.buttons.push({
            stationId: button.stationId,
            target: button.target,
            text: button.text
        });
    },

    deleteButtonsFromStation (state, stationId) {
        state.buttons =
            state.buttons
                .filter((item) => {
                    return (item.stationId != stationId);
                });
    },

    unseenNews (state, unseenNews) {
        state.unseenNews = unseenNews;
    },

    lastActiveStationId (state, lastActiveStationId) {
        state.lastActiveStationId = lastActiveStationId;
    }
};

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
};
