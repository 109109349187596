/**
 * Calculate message delay based on text length
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */

import config from '?/Config';
import constants from '?/Constants';


/**
 * Calculate message delay based on text length
 *
 * @param {string} text String on which the calculation is based
 *
 * @return {int} Time in ms
 */
export const calculateDelay = (text) => {
    let delay = constants.defaultUnreadMessageDelay;

    if (text) {
        delay = constants.timePerCharacterInMs * text.length;
    }

    if (delay > constants.defaultUnreadMessageDelay) {
        delay = constants.defaultUnreadMessageDelay;
    }

    if (config.debug) {
        console.log(
            'Tools::calculateDelay: ',
            'Calculated delay: ' + delay + ' ms, ',
            'Text: ' + text
        );
    }

    return delay;
};
