<!--
/**
 * Single story template
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { computed } from 'vue';
    import { useRoute } from 'vue-router';
    import store from '?/Stores';
    import { story as storyData } from '?/ProjectData';
    import { getAssetPathForStories } from '?/Composable/Tools';
    import repository from '?/Composable/Repository';
    import AudioPlayer from '?/Component/Library/AudioPlayer';
    import AudioEventModel from '?/Model/AudioEventModel';

    // Initialization
    const route = useRoute();

    // Data
    const story = computed(() => {
        const totalPoints = store.getters.stationsWithPointsCount;
        let story = Object.values(store.getters.stories)
            .find((item) => {
                // Filter by id
                if (item.storyId != route.params.id) {
                    return false;
                }

                // Filter by `live` status
                if (!item.live) {
                    return false;
                }

                // Filter by points
                const currentStoryData = storyData.find((data) => {
                    return data.id == item.storyId;
                });

                if (currentStoryData) {
                    return (currentStoryData.points <= totalPoints);
                }

                return false;
            });

        // Mark story as "seen"
        if (story && story.live && !story.done) {
            repository.activateStory(story.storyId);
        }

        return story;
    });

    const events = computed(() => {
        let e = [];

        // let customEvent = new AudioEventModel();
        //
        // customEvent.eventName = 'timeupdate';
        // customEvent.time = 5;
        // customEvent.repeatable = false;
        // customEvent.callback = (e) => {
        //     console.log('Fired');
        // };
        //
        // e.push(customEvent);

        return e;
    });

    // Functions
    const data = (id) => {
        return storyData.find((data) => {
            return data.id == id;
        });
    };
</script>

<template>
    <div id="story" class="story-wrapper" v-if="story">
        <div class="story-teaser">
            <div class="story-image">
                <img :src="getAssetPathForStories() + '/story-' + story.storyId + '.jpg'"
                     alt="" />
            </div>
            <div class="story-audio">
                <AudioPlayer :src="getAssetPathForStories() + '/story-' + story.storyId + '.mp3'"
                             :range="false"
                             :events="events" />
            </div>
        </div>
        <h2 class="story-chapter">
            {{ $t('story.chapter', {name: data(story.storyId).chapter}) }}
        </h2>
        <div class="story-content">
            <h3 class="story-name">{{ data(story.storyId).headline }}</h3>
            <p class="story-text" v-html="data(story.storyId).text"></p>
        </div>
    </div>
</template>
