/**
 * Functions collection without dependencies
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */


/**
 * Deep merge two objects
 *
 * @param {object} target Target object
 * @param {object} source Source object
 *
 * @returns {object} - Merged object
 */
export const mergeObjects = (target, source) => {
    // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
    for (const key of Object.keys(source)) {
        if (source[key] instanceof Object) {
            Object.assign(source[key], mergeObjects(target[key], source[key]));
        }
    }

    // Join `target` and modified `source`
    Object.assign(target || {}, source);

    return target;
};
