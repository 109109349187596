<!--
/**
 * Webapp team join template
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { reactive, computed, inject } from 'vue';
    import { useVuelidate } from '@vuelidate/core';
    import { required } from '@vuelidate/validators';
    import store from '?/Stores';
    import filter from '?/Composable/Filter';
    import repository from '?/Composable/Repository';
    import emitter from '?/Composable/Events/Emitter';
    import config from '?/Config';

    // Data
    const state = reactive({
        isLoading: false,
        apiErrors: [],
        teamCode: '',
        joinedTeam: false
    });

    // Functions
    const formSubmit = async () => {
        state.isLoading = true;
        const validation = await v$._value.$validate();

        if (!validation) {
            state.isLoading = false;
            return;
        }

        let teamCode = filter.teamCode(state.teamCode);

        repository.joinTeam(teamCode)
            .then((response) => {
                // @event team-joined
                emitter.emit('team-joined', teamCode);

                state.joinedTeam = true;

                // @FIXME Remove if API updates `playerstatus` after invite
                store.commit('hasTeam', true);

                return response;
            })
            .catch((error) => {
                state.apiErrors = [error];

                return error;
            })
            .finally(() => {
                state.isLoading = false;
            });
    };

    // Validation
    const rules = computed(() => ({
        teamCode: {
            required
        }
    }));
    const v$ = useVuelidate(rules, state);
</script>

<template>
    <template v-if="store.getters.isAuthenticated && config.allowTeams">
        <div class="team-join"
             v-if="!state.joinedTeam">
            <form id="team-join-form"
                  class="team-join-form"
                  method="post"
                  action="./"
                  v-on:submit.prevent="formSubmit">
                <fieldset class="team-join-code">
                    <legend>{{ $t('team.team-code')}}</legend>

                    <p class="error" v-if="state.apiErrors.length">
                        <span class="input-errors" v-for="error of state.apiErrors" :key="error.code">
                            <span class="error-msg">{{ $t('apiError.team.' + error.code) }}</span>
                        </span>
                    </p>

                    <p v-html="$t('team.join-description')"></p>

                    <p class="team-join-form-code"
                       :class="{ error: v$.teamCode.$errors.length }">
                        <span class="input-errors" v-for="error of v$.teamCode.$errors" :key="error.$uid">
                            <span class="error-msg">{{ $t('vuelidate.' + error.$message) }}</span>
                        </span>
                        <label for="team-code">{{ $t('team.team-code')}}</label>
                        <input type="text"
                               id="team-code"
                               v-model="state.teamCode"
                               :placeholder="$t('team.team-code')" />
                    </p>

                    <p>
                        <input type="submit" class="button" :value="$t('team.send-code')" :disabled="state.isLoading" />
                    </p>
                </fieldset>
            </form>
        </div>
        <p v-else
           v-html="$t('team.invite-successful-description')"></p>
    </template>
</template>
