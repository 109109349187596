/**
 * Check type of web content
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */


/**
 * Check type of web content
 *
 * @param {string} message Message to process
 *
 * @return {string} Processed message object
 */
export const checkWebContentType = (message) => {
    var localImgRegEx = new RegExp('^(?!(http|https|ftp|ftps)).*\.(jpeg|jpg|png|svg)$', 'gmi'),
        localAudioRegEx = new RegExp('^(?!(http|https|ftp|ftps)).*\.(mp3)$', 'gmi'),
        localVideoRegEx = new RegExp('^(?!(http|https|ftp|ftps)).*\.(mp4|webm)$', 'gmi'),
        urlRegEx = new RegExp('^(https?:\/\/[^\\s]+)', 'gmi');

    // Local images
    if (localImgRegEx.test(message)) {
        return 'image';
    }

    // URLs
    if (urlRegEx.test(message)) {
        return 'url';
    }

    // Audio
    if (localAudioRegEx.test(message)) {
        return 'audio';
    }

    // Video
    if (localVideoRegEx.test(message)) {
        return 'video';
    }

    return 'text';
};
