/**
 * Emitter for webapp
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */

import mitt from 'mitt';

const emitter = mitt();

export default emitter;
