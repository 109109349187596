<!--
/**
 * Single knight template
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { computed } from 'vue';
    import { useRoute } from 'vue-router';
    import store from '?/Stores';
    import { knights as knightsData } from '?/ProjectData';
    import { getAssetPath } from '?/Composable/Tools';
    import repository from '?/Composable/Repository';
    import AudioPlayer from '?/Component/Library/AudioPlayer';
    import AudioEventModel from '?/Model/AudioEventModel';

    // Initialization
    const route = useRoute();

    const knight = computed(() => {
         return route.params.id;
    });

    // Functions
    const data = (id) => {
        return knightsData.find((data) => {
            return data.id == id;
        });
    };
</script>

<template>
    <div id="knight" class="knight" :class="'knight-' + knight">
        <div class="knight-wrapper" v-if="data(knight)">
            <div class="knight-teaser">
                <div class="knight-image">
                    <img :src="getAssetPath() + '/knights/' + knight + '.svg'"
                         alt="" />
                </div>
                <div class="knight-audio">
                    <AudioPlayer :src="getAssetPath() + '/knights/' + knight + '.mp3'"
                                 :range="false"
                                 :events="events" />
                </div>
            </div>
            <div class="knight-content">
                <h2 class="knight-name">{{ data(knight).headline }}</h2>
                <p class="knight-text" v-html="data(knight).text"></p>
            </div>
        </div>
    </div>
</template>
