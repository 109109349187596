/**
 * CamelCase to dashed-case
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */


/**
 * CamelCase to dashed-case
 *
 * @param {string} string String to convert
 *
 * @return {void}
 */
export const camelToDashedCase = (string) => {
    return string.replace(
        /[A-Z]/g,
        (match, offset) => (offset > 0 ? '-' : '') + match.toLowerCase()
    );
};
