<!--
/**
 * Webapp logout template
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import store from '?/Stores';
    import repository from '?/Composable/Repository';
    import { routeTo } from '?/Composable/Tools';

    // Functions
    const logout = () => {
        store.commit('authenticated', false);
        repository.logout();

        routeTo('/webapp/login');
    };
</script>

<template>
    <div class="logout"
         v-if="store.getters.isAuthenticated">
        <p v-html="$t('logout.description')"></p>
        <button class="logout-button"
                @click="logout()">
            {{ $t('logout.label') }}
        </button>
    </div>
</template>
