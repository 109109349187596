<!--
/**
 * Login component
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { reactive, computed, inject } from 'vue';
    import { useVuelidate } from '@vuelidate/core';
    import { required } from '@vuelidate/validators';
    import filter from '?/Composable/Filter';
    import repository from '?/Composable/Repository';
    import emitter from '?/Composable/Events/Emitter';
    import config from '?/Config';

    // Data
    const state = reactive({
        isLoading: false,
        apiErrors: [],
        identifier: '',
        secret: '',
        authenticated: repository.isAuthenticated()
    });

    // Functions
    const formSubmit = async () => {
        state.isLoading = true;
        const validation = await v$._value.$validate();

        if (!validation) {
            state.isLoading = false;
            return;
        }

        let identifier = filter.phoneNumber(state.identifier);
        let secret = filter.password(state.secret);

        repository.authenticationPhone(identifier, secret)
            .then((response) => {
                if (repository.isAuthenticated()) {
                    // @event login-is-authenticated
                    emitter.emit('login-is-authenticated', repository.isAuthenticated());
                }

                return response;
            })
            .catch((error) => {
                state.apiErrors = [error];

                return error;
            })
            .finally(() => {
                state.isLoading = false;
            });
    };

    // Validation
    const rules = computed(() => ({
        identifier: {
            required
        },
        secret: {
            required
        }
    }));
    const v$ = useVuelidate(rules, state);
</script>

<template>
    <div class="login-form-wrapper" v-if="!state.authenticated">
        <form id="login-form"
              class="login-form"
              method="post"
              action="./"
              autocomplete="off"
              v-on:submit.prevent.capture="formSubmit">

            <fieldset class="login-form-credentials">
                <legend>{{ $t('account.credentials')}}</legend>

                <p class="error" v-if="state.apiErrors.length">
                    <span class="input-errors" v-for="error of state.apiErrors" :key="error.code">
                        <span class="error-msg">{{ $t('apiError.' + error.code) }}</span>
                    </span>
                </p>

                <p class="login-form-credentials-identifier"
                   :class="{ error: v$.identifier.$errors.length }">
                    <span class="input-errors" v-for="error of v$.identifier.$errors" :key="error.$uid">
                        <span class="error-msg">{{ $t('vuelidate.' + error.$message) }}</span>
                    </span>
                    <label for="login-identifier">{{ $t('account.phone-number')}}</label>
                    <input type="text" id="login-identifier" v-model="state.identifier" :placeholder="$t('account.identifier-placeholder')" />
                </p>

                <p class="login-form-credentials-secret"
                   :class="{ error: v$.secret.$errors.length }">
                    <span class="input-errors" v-for="error of v$.secret.$errors" :key="error.$uid">
                        <span class="error-msg">{{ $t('vuelidate.' + error.$message) }}</span>
                    </span>
                    <label for="login-secret">{{ $t('account.password')}}</label>
                    <input type="password" id="login-secret" v-model="state.secret" :placeholder="$t('account.secret-placeholder')" />
                </p>

                <p>
                    <input type="submit" class="button" :value="$t('account.label')" :disabled="state.isLoading" />
                </p>
            </fieldset>

      </form>
    </div>
    <div v-else>
        <form :action="config.baseUrl + 'webapp/'">
            <button type="submit" class="button-logged-in">
                {{ $t('account.alreay-authenticated') }}
            </button>
        </form>
    </div>
</template>
