<!--
/**
 * Station messages template
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { reactive, defineProps, computed, markRaw, onUnmounted } from 'vue';
    import config from '?/Config';
    import store from '?/Stores';
    import { processTimestamp } from '?/Composable/Tools';
    import emitter from '?/Composable/Events/Emitter';
    import Webcontent from '?/Component/Webapp/MessageTypes/Webcontent';
    import WebcontentRecommendation from '?/Component/Webapp/MessageTypes/WebcontentRecommendation';
    import Webtext from '?/Component/Webapp/MessageTypes/Webtext';
    import WebtextRecommendation from '?/Component/Webapp/MessageTypes/WebtextRecommendation';

    // Data
    const props = defineProps({
        stationId: {
            type: Number,
            required: true
        }
    });

    const messages = computed(() => {
        return store.getters.messagesforStation(props.stationId);
    });

    const unreadMessages = computed(() => {
        return store.getters.unreadMessagesforStation(props.stationId);
    });

    const isWriting = computed(() => {
        return store.getters.isWriting;
    });

    // Custom setup logic
    store.commit('markMessagesAsReadForStation', props.stationId);

    // Events
    const markAsReadEventFunction = (message) => {
        store.commit('markMessagesAsReadForStation', props.stationId);

        if (config.debug) {
            console.log(
                'Message event `unread-message-added`: ',
                'Mark message as read for station `' + props.stationId + '`',
                'Message: ', message
            );
        }
    };

    emitter.on('unread-message-added', markAsReadEventFunction);
    onUnmounted(() => emitter.off('unread-message-added', markAsReadEventFunction));

    // Functions
    function component (type) {
        let component = null;

        switch(type) {
            case 'webcontent':
                component = markRaw(Webcontent);

                break;
            case 'webcontent-recommendation':
                component = markRaw(WebcontentRecommendation);

                break;
            case 'webtext':
                component = markRaw(Webtext);

                break;
            case 'webtext-recommendation':
                component = markRaw(WebtextRecommendation);

                break;
            default:
                component = markRaw(Webtext);
        };

        return component;
    }
</script>

<template>
    <div class="messages-wrapper">
        <ul class="read" v-if="messages.length">
            <li class="message"
                v-for="message in messages"
                :class="{ 'message-user' : message.originator == 'user'}">
                <div class="meta">
                    <strong class="author" v-if="message.originator != 'user'">{{ message.originator }}</strong>
                    <strong class="author" v-if="message.originator == 'user'">{{ $t('messages.me') }}</strong>,
                    <span class="time">{{ processTimestamp(message.timestamp) }}</span>:
                </div>
                <component :is="component(message.type)"
                           :message="message"></component>
            </li>
        </ul>
        <div class="unread" v-if="unreadMessages.length || isWriting">
            <p class="unread-message-label"
               v-if="unreadMessages.length">
                {{ $t('messages.new-messages') }}
            </p>
            <ul>
                <li class="message"
                    v-for="message in unreadMessages"
                    :class="{ 'message-user' : message.originator == 'user'}">
                    <div class="meta">
                        <strong class="author" v-if="message.originator != 'user'">{{ message.originator }}</strong>
                        <strong class="author" v-if="message.originator == 'user'">{{ $t('messages.me') }}</strong>,
                        <span class="time">{{ processTimestamp(message.timestamp) }}</span>:
                    </div>
                    <component :is="component(message.type)"
                               :message="message"></component>
                </li>

                <li class="message is-writing" v-if="isWriting">
                    <svg viewBox="0 0 40 20" class="loader">
                        <circle class="dot" cx="10" cy="10" r="3" style="fill:grey;" />
                        <circle class="dot" cx="20" cy="10" r="3" style="fill:grey;" />
                        <circle class="dot" cx="30" cy="10" r="3" style="fill:grey;" />
                    </svg>
                </li>
            </ul>
        </div>
    </div>
</template>

<style scoped>
    .message.is-writing {

        width: 2.875rem;
        line-height: 1;
        padding: .3em;

    }

    @keyframes blink { 50% { fill: transparent }}

    .is-writing .dot {

        animation: 1s blink infinite;
        fill: transparent;

    }

    .is-writing .dot:nth-child(2) {

        animation-delay: 250ms;

    }

    .is-writing .dot:nth-child(3) {

        animation-delay: 500ms;

    }

    .is-writing .loader {

        width: 100%;
        height: auto;

        aspect-ratio: 2 / 1;

        color: grey;
        margin: 0 auto;
        display: block;

    }
</style>
