/**
 * Get asset path for messages
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */

import { getAssetPath } from '?/Composable/Tool/GetAssetPath';


/**
  * Get asset path for messages
  *
  * @return {string} Asset path
  */
export const getAssetPathForMessages = () => {
    return getAssetPath() + '/messages';
};
