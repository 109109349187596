<!--
/**
 * Modal component for sharing a link or text
 *
 * Usage:
 *
 * ```
 * import ShareModal from '?/Component/ModalContent/Share';
 *
 * // @event activate-modal
 * emitter.emit('activate-modal', {
 *     component: TextModal,
 *     title: 'Test Title',
 *     defaultCloseButton: true,
 *     data: {
 *         title: 'Lorem Ipsum'
 *         text: 'Lorem ipsum dolor sit amet',
 *         url: 'https://example.com'
 *     }
 * });
 * ```
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { defineProps, reactive, onMounted } from "vue";
    import QrCreator from 'qr-creator';
    import { t } from '?/Composable/Translation';
    import store from '?/Stores';

    // Get parent data
    const props = defineProps({
        data: {
            type: Object,
            required: true
          }
    });

    // Data
    let shareData = {
        title: props.data.shareSheetTitle || t('modal.share-default-title'),
        text: props.data.text || t('modal.share-default-text'),
        url: props.data.url
    };

    const state = reactive({
        shareButtonLabel: (navigator.canShare && navigator.canShare(shareData))
            ? t('modal.share')
            : t('modal.copy'),
        showShareButton: (navigator.canShare && navigator.canShare(shareData)) || navigator.clipboard,
        sharedString: props.data.url || props.data.text
    });

    // Methods
    const shareAction = () => {
        if (navigator.canShare && navigator.canShare(shareData)) {
            navigator.share(shareData);
        } else {
            navigator.clipboard.writeText(state.sharedString)
                .then(() => {
                    state.shareButtonLabel = t('modal.copied');
                })
                .catch((error) => {
                    store.commit('flashMessage', t('modal.error-copying-to-clipboard'));
                });
        }
    };

    onMounted(() => {
        if (state.sharedString) {
            QrCreator.render({
                text: state.sharedString,
                radius: 0.5, // 0.0 to 0.5
                ecLevel: 'H', // L, M, Q, H
                fill: '#ffffff', // foreground color
                background: null, // color or null for transparent
                size: 528 // in pixels
            }, document.querySelector('#qr-code'));
        }
    });
</script>

<template>
    <div class="share-string-wrapper" v-if="state.sharedString">
        <button class="share-string-button"
                v-if="state.showShareButton"
                @click="shareAction()">
            {{ state.shareButtonLabel }}
        </button>
        <div class="share">
            <textarea readonly>{{ state.sharedString }}</textarea>
        </div>
        <div id="qr-code"
             class="share-string-qr-code"></div>
    </div>
</template>
