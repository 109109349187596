/**
 * Global application store
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */

import { createStore, createLogger } from 'vuex';
import VuexPersistence from 'vuex-persist';
import playerstatus from '?/Store/Playerstatus';
import settings from '?/Store/Settings';
import messages from '?/Store/Messages';
import temporary from '?/Store/Temporary';

// Partially persist store in `localStorage`
const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    reducer: (state) => ({
        messages: state.messages,
        settings: state.settings
    }),
    filter: (mutation) => (
        mutation.type == 'authenticated' ||
        mutation.type == 'unseenNews' ||
        mutation.type == 'addMessage' ||
        mutation.type == 'addUnreadMessage' ||
        mutation.type == 'addUserMessage' ||
        mutation.type == 'markAllMessagesAsRead' ||
        mutation.type == 'markMessagesAsReadForStation' ||
        mutation.type == 'deleteMessagesForStation' ||
        mutation.type == 'addStationToAnswerExpected' ||
        mutation.type == 'addStationToAnswerRejected' ||
        mutation.type == 'deleteStationFromAnswerExpected' ||
        mutation.type == 'deleteStationFromAnswerRejected' ||
        mutation.type == 'addStationToButtonInputExpected' ||
        mutation.type == 'deleteStationFromButtonInputExpected' ||
        mutation.type == 'addButtonToStation' ||
        mutation.type == 'deleteButtonsFromStation' ||
        mutation.type == 'addUnseenStationCount' ||
        mutation.type == 'deleteUnseenStationCount'
    )
});

const store = createStore({
    modules: {
        playerstatus: playerstatus,
        settings: settings,
        messages: messages,
        temporary: temporary
    },
    plugins: [vuexLocal.plugin]
});

export default store;
