/**
 * Get nearest station
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */

import constants from '?/Constants';
import store from '?/Stores';


/**
 * Get nearest station
 *
 * @return {StationModel|null} Nearest station
 */
export const getNearestStation = () => {
    let stationTmp = null;
    const distanceThreshold = constants.stationDistanceThreshold || 3000;

    store.getters.processedStations().forEach((station) => {
        if (
            station.distance < distanceThreshold &&
            station.distance != 0 &&
            !station.done
        ) {
            stationTmp = (!stationTmp)
                ? station
                : (station.distance < stationTmp.distance)
                    ? station
                    : stationTmp;
        }
    });

    return stationTmp;
};
