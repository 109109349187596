<!--
/**
 * Modal component for opt-in
 *
 * Usage:
 *
 * ```
 * import OptInModal from '?/Component/ModalContent/OptIn';
 *
 * // @event activate-modal
 * emitter.emit('activate-modal', {
 *     component: OptInModal
 * });
 * ```
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { reactive, computed, inject, defineProps } from 'vue';
    import { useVuelidate } from '@vuelidate/core';
    import { required } from '@vuelidate/validators';
    import filter from '?/Composable/Filter';
    import repository from '?/Composable/Repository';
    import emitter from '?/Composable/Events/Emitter';

    // Get parent data
    const props = defineProps({
        data: {
            type: Object,
            required: true
          }
    });

    // Data
    const state = reactive({
        isLoading: false,
        apiErrors: [],
        identifier: props.data.identifier,
        code: '',
        submitted: false,
    });

    // Functions
    const formSubmit = async () => {
        state.isLoading = true;
        const validation = await v$._value.$validate();

        if (!validation) {
            state.isLoading = false;
            return;
        }

        let identifier = filter.phoneNumber(state.identifier);
        let code = filter.password(state.code);

        repository.verificationPhone(identifier, code)
            .then((response) => {
                state.submitted = true;

                // @event opt-in-successful
                emitter.emit('opt-in-successful', identifier);

                return response;
            })
            .catch((error) => {
                state.apiErrors = [error];

                return error;
            })
            .finally(() => {
                state.isLoading = false;
            });
    };

    // Validation
    const rules = computed(() => ({
        identifier: {
            required
        },
        code: {
            required
        }
    }));
    const v$ = useVuelidate(rules, state);
</script>

<template>
    <form id="opt-in-form"
              class="opt-in-form"
              method="post"
              action="./"
              autocomplete="off"
              v-on:submit.prevent.capture="formSubmit">

        <p v-html="$t('account.confirm-description')"></p>

        <fieldset class="opt-in-form-data">
            <legend>{{ $t('account.opt-in-data')}}</legend>

            <p class="error" v-if="state.apiErrors.length">
                <span class="input-errors" v-for="error of state.apiErrors" :key="error.code">
                    <span class="error-msg">{{ $t('apiError.' + error.code) }}</span>
                </span>
            </p>

            <p class="opt-in-form-data-identifier"
               :class="{ error: v$.identifier.$errors.length }"
               v-if="!props.data.identifier">
                <span class="input-errors" v-for="error of v$.identifier.$errors" :key="error.$uid">
                    <span class="error-msg">{{ $t('vuelidate.' + error.$message) }}</span>
                </span>
                <label for="opt-in-identifier">{{ $t('account.phone-number')}}</label>
                <input type="text" id="opt-in-identifier" v-model="state.identifier" :placeholder="$t('account.identifier-placeholder')" />
            </p>

            <p class="opt-in-form-data-code"
               :class="{ error: v$.code.$errors.length }">
                <span class="input-errors" v-for="error of v$.code.$errors" :key="error.$uid">
                    <span class="error-msg">{{ $t('vuelidate.' + error.$message) }}</span>
                </span>
                <label for="opt-in-code">{{ $t('account.code')}}</label>
                <input type="text"
                       id="opt-in-code"
                       v-model="state.code"
                       :placeholder="$t('account.code-placeholder')"
                       autocomplete="one-time-code" />
            </p>

            <p>
                <input type="submit" :value="$t('account.confirm')" :disabled="state.isLoading" />
            </p>
        </fieldset>

    </form>
</template>
