/**
 * Route to custom route
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */

import router from '?/Router/Webapp';


/**
 * Route to custom route
 *
 * @param {string} route        Route
 * @param {bool}   addTarget    Add current route as parameter
 * @param {bool}   addToHistory Should the forwarding be added to the history
 *
 */
export const routeTo = (route, addTarget, addToHistory) => {
    router.isReady().then(() => {
        if (
            router.currentRoute.value.path !== route &&
            router.currentRoute.value.name !== '404'
        ) {
            let target = encodeURI(router.currentRoute.value.path);

            route += (addTarget && target !== '/' && target !== '/webapp/')
                ? `?target=${target}`
                : '';

            if (addToHistory) {
                router.replace(route);
            } else {
                router.push(route);
            }
        }
    });
};
