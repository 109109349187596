<!--
/**
 * Webapp debug template
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { reactive, computed, inject } from 'vue';
    import { useVuelidate } from '@vuelidate/core';
    import { required } from '@vuelidate/validators';
    import store from '?/Stores';
    import filter from '?/Composable/Filter';
    import repository from '?/Composable/Repository';
    import emitter from '?/Composable/Events/Emitter';
    import config from '?/Config';

    // Data
    const state = reactive({
        isLoading: false,
        authToken: repository.getAuthToken(),
        authCode: ''
    });

    // Functions
    const authTokenFormSubmit = async () => {
        state.isLoading = true;
        const validation = await v$._value.$validate();

        if (!validation) {
            state.isLoading = false;
            return;
        }

        state.authToken = filter.authCode(state.authCode);
        repository.setAuthToken(state.authToken);
        state.isLoading = false;

        window.location.reload();
    };

    // Validation
    const rules = computed(() => ({
        authCode: {
            required
        }
    }));
    const v$ = useVuelidate(rules, state);
</script>

<template>
    <template v-if="config.debug">
        <div class="debug">
            <form id="debug-auth-token-form"
                  class="debug-auth-token-form"
                  method="post"
                  action="./"
                  v-on:submit.prevent="authTokenFormSubmit">
                <fieldset class="debug-auth-token">
                    <legend>{{ $t('debug.auth-token')}}</legend>

                    <p v-html="$t('debug.auth-token-description', {'token': state.authToken})"></p>

                    <p class="debug-auth-token-form-auth-code"
                       :class="{ error: v$.authCode.$errors.length }">
                        <span class="input-errors" v-for="error of v$.authCode.$errors" :key="error.$uid">
                            <span class="error-msg">{{ $t('vuelidate.' + error.$message) }}</span>
                        </span>
                        <label for="auth-code">{{ $t('debug.auth-token')}}</label>
                        <input type="text"
                               id="auth-code"
                               v-model="state.authCode"
                               :placeholder="$t('debug.auth-token')" />
                    </p>

                    <p class="debug-auth-token-form-actions">
                        <input type="submit"
                               class="button"
                               :value="$t('debug.send')"
                               :disabled="state.isLoading" />
                    </p>
                </fieldset>
            </form>
        </div>
    </template>
</template>
