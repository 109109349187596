<!--
/**
 * Modal component for change password form
 *
 * Usage:
 *
 * ```
 * import ChangePasswordModal from '?/Component/ModalContent/ChangePassword';
 *
 * // @event activate-modal
 * emitter.emit('activate-modal', {
 *     component: ChangePasswordModal,
 *     data: {
 *         token: 'abc123'
 *     }
 * });
 * ```
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { reactive, computed, defineProps } from 'vue';
    import { useVuelidate } from '@vuelidate/core';
    import { required, sameAs, minLength } from '@vuelidate/validators';
    import filter from '?/Composable/Filter';
    import repository from '?/Composable/Repository';
    import emitter from '?/Composable/Events/Emitter';

    // Get parent data
    const props = defineProps({
        data: {
            type: Object,
            required: true
          }
    });

    // Data
    const state = reactive({
        isLoading: false,
        apiErrors: [],
        secret: '',
        secret2: '',
        submitted: false,
    });

    // Functions
    const formSubmit = async () => {
        state.isLoading = true;
        const validation = await v$._value.$validate();

        if (!validation) {
            state.isLoading = false;
            return;
        }

        let secret = filter.password(state.secret);

        repository.changePassword(secret, props.data.token)
            .then((response) => {
                // @event password-reset-sent
                emitter.emit('password-changed', secret);

                state.submitted = true;

                return response;
            })
            .catch((error) => {
                state.apiErrors = [error];

                return error;
            })
            .finally(() => {
                state.isLoading = false;
            });
    };

    // Validation
    const rules = computed(() => ({
        secret: {
            required, minLength: minLength(6)
        },
        secret2: {
            required, sameAsPassword: sameAs(state.secret)
        }
    }));
    const v$ = useVuelidate(rules, state);
</script>

<template>
    <form id="change-password-form"
              class="change-password-form"
              method="post"
              action="./"
              autocomplete="off"
              v-on:submit.prevent.capture="formSubmit"
              v-if="!state.submitted">

        <fieldset class="change-password-form-credentials">
            <legend>{{ $t('account.secrets')}}</legend>

            <p class="error" v-if="state.apiErrors.length">
                <span class="input-errors" v-for="error of state.apiErrors" :key="error.code">
                    <span class="error-msg">{{ $t('apiError.' + error.code) }}</span>
                </span>
            </p>

            <p class="change-password-form-credentials-secret"
               :class="{ error: v$.secret.$errors.length }">
                <span class="input-errors" v-for="error of v$.secret.$errors" :key="error.$uid">
                    <span class="error-msg">{{ $t('vuelidate.' + error.$message) }}</span>
                </span>
                <label for="new-secret">{{ $t('account.password')}}</label>
                <input type="password" id="new-secret" v-model="state.secret">
            </p>

            <p class="change-password-form-credentials-secret-2"
               :class="{ error: v$.secret.$errors.length }">
                <span class="input-errors" v-for="error of v$.secret2.$errors" :key="error.$uid">
                    <span class="error-msg">{{ $t('vuelidate.' + error.$message) }}</span>
                </span>
                <label for="new-secret-2">{{ $t('account.repeat-password')}}</label>
                <input type="password" id="new-secret-2" v-model="state.secret2">
            </p>

            <p>
                <input type="submit" :value="$t('account.change-password')" :disabled="state.isLoading" />
            </p>
        </fieldset>

    </form>
    <div class="change-password-message"
         v-if="state.submitted">
        <p v-html="$t('account.change-password-successful')"></p>
        <p>
            <a href="webapp/">{{ $t('account.go-to-webapp') }}</a>
        </p>
    </div>
</template>
