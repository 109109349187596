<!--
/**
 * Single scoreboard template (activation only, like story)
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>

 </script>

<template>
    <div id="station-scoreboard-detail" class="station-scoreboard-detail station-type__activation-only">
        @TODO: TBD
    </div>
</template>
