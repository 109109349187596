/**
 * Media event model class
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */

import BaseModel from '?/Model/BaseModel';


export default class MediaEventModel extends BaseModel
{
    #eventName = null;
    #callback = null;
    #time = null;
    #stepInPercent = null;
    #repeatable = null;
    #fired = null;


    get eventName () {
        return this.#eventName;
    }

    set eventName (eventName) {
        this.#eventName = eventName;
    }


    get callback () {
        return this.#callback;
    }

    set callback (callback) {
        this.#callback = () => {
            if (
                callback instanceof Function &&
                (this.#repeatable || !this.#fired)
            ) {
                callback(this);
            }
            this.#fired = true;
        }
    }


    get time () {
        return this.#time;
    }

    set time (time) {
        this.#time = time;
    }


    get stepInPercent () {
        return this.#stepInPercent;
    }

    set stepInPercent (stepInPercent) {
        this.#stepInPercent = stepInPercent;
    }


    get repeatable () {
        return this.#repeatable;
    }

    set repeatable (repeatable) {
        this.#repeatable = repeatable;
    }


    get fired () {
        return this.#fired;
    }

    set fired (fired) {
        this.#fired = fired;
    }
}
