/**
 * Project data
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */

import config from '?/Config';
import store from '?/Stores';

const projectDataRaw = _PROJECT_DATA;

let all,
    news,
    main,
    station,
    story,
    scoreboard,
    archive,
    email,
    point,
    help,
    privacy,
    knights,
    hostel;

news = (projectDataRaw.hasOwnProperty('news') && projectDataRaw.news.hasOwnProperty(store.getters.language))
    ? projectDataRaw.news[store.getters.language]
    : [];

main = (projectDataRaw.hasOwnProperty('main') && projectDataRaw.main.hasOwnProperty(store.getters.language))
    ? projectDataRaw.main[store.getters.language]
    : [];

station = (projectDataRaw.hasOwnProperty('station') && projectDataRaw.station.hasOwnProperty(store.getters.language))
    ? projectDataRaw.station[store.getters.language]
    : [];

story = (projectDataRaw.hasOwnProperty('story') && projectDataRaw.story.hasOwnProperty(store.getters.language))
    ? projectDataRaw.story[store.getters.language]
    : [];

scoreboard = (projectDataRaw.hasOwnProperty('scoreboard') && projectDataRaw.scoreboard.hasOwnProperty(store.getters.language))
    ? projectDataRaw.scoreboard[store.getters.language]
    : [];

archive = (projectDataRaw.hasOwnProperty('archive') && projectDataRaw.archive.hasOwnProperty(store.getters.language))
    ? projectDataRaw.archive[store.getters.language]
    : [];

email = (projectDataRaw.hasOwnProperty('email') && projectDataRaw.email.hasOwnProperty(store.getters.language))
    ? projectDataRaw.email[store.getters.language]
    : [];

point = (projectDataRaw.hasOwnProperty('point') && projectDataRaw.point.hasOwnProperty(store.getters.language))
    ? projectDataRaw.point[store.getters.language]
    : [];

help = (projectDataRaw.hasOwnProperty('help') && projectDataRaw.help.hasOwnProperty(store.getters.language))
    ? projectDataRaw.help[store.getters.language]
    : [];

privacy = (projectDataRaw.hasOwnProperty('privacy') && projectDataRaw.privacy.hasOwnProperty(store.getters.language))
    ? projectDataRaw.privacy[store.getters.language]
    : [];

knights = (projectDataRaw.hasOwnProperty('knights') && projectDataRaw.knights.hasOwnProperty(store.getters.language))
    ? projectDataRaw.knights[store.getters.language]
    : [];

hostel = (projectDataRaw.hasOwnProperty('hostel') && projectDataRaw.hostel.hasOwnProperty(store.getters.language))
    ? projectDataRaw.hostel[store.getters.language]
    : [];

all = {
    all: all,
    news: news,
    main: main,
    station: station,
    story: story,
    scoreboard: scoreboard,
    archive: archive,
    email: email,
    point: point,
    help: help,
    privacy: privacy,
    knights: knights,
    hostel: hostel
};

export { all, news, main, station, story, scoreboard, archive, email, point, help, privacy, knights, hostel };
