<!--
/**
 * Intro template
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { reactive } from 'vue';
    import store from '?/Stores';
    import { routeTo, getAssetPathForIntro } from '?/Composable/Tools';
    import AudioPlayer from '?/Component/Library/AudioPlayer';

    // Data
    const state = reactive({
        step: 1,
        stepCount: 8
    });
</script>

<template>
    <div class="intro-wrapper">
        <div class="intro-content">
            <template v-if="state.step === 1">
                <h2>Kurze Anleitung</h2>
                <p>
                    Grüßt euch!<br />
                    Bevor ihr eure Ritter-Power unter Beweis stellen dürft, möchten wir euch kurz die wichtigsten Dinge erklären.
                </p>
                <p>
                    Ihr könnt diese Infos jederzeit erneut unter „Tipps und Regeln“ abrufen, wenn ihr gerade keine Zeit habt.
                </p>
            </template>
            <template v-if="state.step === 2">
                <h2>GPS Hinweis</h2>
                <p>
                    Gleich könnt ihr loslegen. Wenn ihr im Browser eure Standortdaten (GPS) freigebt, können wir euch noch bessere Tipps geben.
                </p>
                <p>
                    Keine Sorge: Eure Daten werden weder gespeichert noch an den Server übertragen.
                </p>
            </template>
            <template v-if="state.step === 3">
                <h2>Eure Mission</h2>
                <p>
                    Wir freuen uns, euch hiermit offiziell im Ritterorden aufzunehmen! Nun geht es darum, eure ritterlichen Tugenden unter Beweis zu stellen.
                </p>
                <p>
                    Eure Mission: Sammelt Punkte, verdient Belohnungen und steigt damit immer weiter im Orden auf!
                </p>
            </template>
            <template v-if="state.step === 4">
                <h2 class="intro-icon news">Icon: News</h2>
                <p>
                    <strong>Zuhause</strong> auf der Burg werdet ihr über wichtige Neuigkeiten informiert - zum Beispiel, wenn sich Stationen in eurer Nähe befinden oder ihr Belohnungen freigeschaltet habt.
                </p>
                <p>
                    Achtet auf das Fähnchen rechts oben, es signalisiert neue Benachrichtigungen!
                </p>
            </template>
            <template v-if="state.step === 5">
                <h2 class="intro-icon stations">Icon: Stationen</h2>
                <p>
                    Startet hier <strong>Stationen</strong> und stellt euch den Aufgaben!<br />
                    Falls ihr eine Station abbrechen müsst, könnt ihr sie später erneut starten.
                </p>
            </template>
            <template v-if="state.step === 6">
                <h2 class="intro-icon knights">Icon: Ritter</h2>
                <p>
                    Hier lernt ihr die Falkenfelser <strong>Ritter</strong> und die Region, in der sie euch prüfen, kennen.
                </p>
                <p>
                    Tipp: Hört euch ihre Geschichten am besten gemeinsam ganz in Ruhe an. Ihr könnt die Audios beliebig oft abspielen.
                </p>
            </template>
            <template v-if="state.step === 7">
                <h2 class="intro-icon stories">Icon: Ruhm</h2>
                <p>
                    Je mehr Stationen ihr abschließt, desto mehr Belohnungen könnt ihr erspielen und damit im Ritterorden aufsteigen!
                </p>
                <p>
                    Schafft ihr es, sogar die Ehrenabzeichen zu erreichen?!
                </p>
            </template>
            <template v-if="state.step === 8">
                <h2 class="intro-icon points">Icon: Punkte</h2>
                <p>
                    Prüft hier eure <strong>Punktezahl</strong> und seht, welche Stationen ihr bereits abgeschlossen habt.
                </p>
                <p>
                    Am besten lernt ihr jetzt erstmal Ritter Reinhardt näher kennen. Er erklärt euch auf der nächsten Seite alles in einem Audio! Tippt ihn einfach an!
                </p>
            </template>
        </div>
        <div class="intro-controls">
            <button type="button"
                    class="intro-controls-previous"
                    v-if="state.step > 1"
                    @click="state.step--">
                {{ $t('intro.previous') }}
            </button>
            <button type="button"
                    class="intro-controls-next"
                    v-if="state.step < state.stepCount"
                    @click="state.step++">
                {{ $t('intro.next') }}
            </button>
            <button type="button"
                    class="intro-controls-final"
                    v-if="state.step === state.stepCount"
                    @click="routeTo('/webapp/knights')">
                {{ $t('intro.go-to-knights') }}
            </button>
            <span class="intro-controls-meta">
                {{ state.step }}/{{ state.stepCount }}
            </span>
        </div>
    </div>
</template>
