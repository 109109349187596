/**
 * Store for `messages` data from CIMMS API
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */

import config from '?/Config';
import constants from '?/Constants';
import emitter from '?/Composable/Events/Emitter';

// Initial state
const state = () => ({
    messages: [],
    unreadMessages: [],
    news: [],
    isWriting: false,
    delayedMessages: [],
    delayedMessageTimeouts: []
})

// Getters
const getters = {
    messages (state) {
        return state.messages;
    },

    unreadMessages (state) {
        return state.unreadMessages;
    },

    news (state) {
        return state.news;
    },

    isWriting (state) {
        return state.isWriting;
    },

    isWritingForStation: (state) => (stationId) => {
        return (state.isWriting == stationId);
    },

    messagesforStation: (state) => (stationId) => {
        return state.messages
            .filter((item) => {
                return (item.stationId == stationId);
            })
            .sort((itemA, itemB) => {
                return itemA.timestamp - itemB.timestamp;
            });
    },

    unreadMessagesforStation: (state) => (stationId) => {
        return state.unreadMessages
            .filter((item) => {
                return (item.stationId == stationId);
            })
            .sort((itemA, itemB) => {
                return itemA.timestamp - itemB.timestamp;
            });
    },

    delayedMessages (state) {
        return state.delayedMessages;
    },

    delayedMessageTimeouts (state) {
        return state.delayedMessageTimeouts;
    },

    delayedMessageTimeoutsforStation: (state) => (stationId) => {
        return state.delayedMessageTimeouts
            .filter((item) => {
                return (item.stationId == stationId);
            });
    },
};

// Actions
const actions = {
    addRecommendationDelayed (context, data) {
        const delay = data.delay || constants.defaultUnreadMessageDelay || 5000;

        setTimeout(() => {
            context.commit('addRecommendation', data);

            // @event recommendation-to-station-added
            emitter.emit('recommendation-to-station-added', data);

            // @event unread-message-added
            emitter.emit('unread-message-added', data.message);
        }, delay);
    },

    addUnreadMessageDelayed (context, data) {
        const unreadMessage = data.content;
        const delay = data.delay || constants.defaultUnreadMessageDelay || 5000;
        const callback = (message) => {
            if (
                context.getters.currentStationId === message.stationId ||
                context.getters.lastActiveStationId === message.stationId
            ) {
                // Remove writing indicator only when no further delayed messages are available
                if (!context.getters.delayedMessageTimeoutsforStation(message.stationId).length) {
                    context.commit('isWriting', false);
                }

                context.commit('addUnreadMessage', message);

                // @event unread-message-added
                emitter.emit('unread-message-added', message);
            }
        };

        // Add delayed message to queue to keep message order despite variable delay
        context.commit('addDelayedMessage', {callback: callback, message: unreadMessage, delay: delay});

        // Start processing delayed messages
        if (!context.getters.delayedMessageTimeoutsforStation(unreadMessage.stationId).length) {
            context.dispatch('processDelayedMessages');
        }
    },

    processDelayedMessages (context) {
        // Stop, when all messages are processed
        if (!context.getters.delayedMessages.length) {
            return false;
        }

        // Get oldest delayed message and delete it from stack
        const delayedMessage = context.getters.delayedMessages.shift();

        // Set writing indicator
        context.commit('isWriting', delayedMessage.message.stationId);

        // @event is-writing
        emitter.emit('is-writing', delayedMessage.message);

        // Run timeout
        const timeout = setTimeout(() => {
            // Delete timeout from store
            context.commit('deleteDelayedMessageTimeout', timeout);

            // Execute callback
            if (typeof delayedMessage.callback === 'function') {
                delayedMessage.callback(delayedMessage.message);
            } else {
                context.commit('isWriting', false);
            }

            // Process further delayed messages
            if (context.getters.delayedMessages.length) {
                context.dispatch('processDelayedMessages');
            }
        }, delayedMessage.delay);

        // Save timeout
        context.commit(
            'addDelayedMessageTimeout',
            {
                stationId: delayedMessage.message.stationId,
                timeout: timeout
            }
        );
    },

    deleteDelayedMessages (context, stationId) {
        context.commit('deleteDelayedMessage', stationId);
        context.commit('deleteDelayedMessageTimeouts', stationId);
        context.commit('isWriting', false);
    }
};

// Mutations
const mutations = {
    addRecommendation (state, data) {
        const message = data.message;

        state.unreadMessages.push({
            stationId: data.stationId,
            originator: message.originator,
            contentRef: message.contentRef || null,
            feature: message.feature || null,
            seenRatio: message.seenRatio || null,
            content: message.content,
            timestamp: message.timestamp,
            type: message.type,
            recommendationId: message.stationId
        });

        // @event recommendation-added
        emitter.emit('recommendation-added', message);
    },

    addMessage (state, message) {
        state.messages.push({
            stationId: message.stationId,
            originator: message.originator,
            contentRef: message.contentRef || null,
            feature: message.feature || null,
            seenRatio: message.seenRatio || null,
            content: message.content,
            timestamp: message.timestamp,
            type: message.type
        });

        // @event recommendation-added
        emitter.emit('message-added', message);
    },

    addUnreadMessage (state, unreadMessage) {
        state.unreadMessages.push({
            stationId: unreadMessage.stationId,
            originator: unreadMessage.originator,
            contentRef: unreadMessage.contentRef || null,
            feature: unreadMessage.feature || null,
            seenRatio: unreadMessage.seenRatio || null,
            content: unreadMessage.content,
            recommendation: (unreadMessage.hasOwnProperty('recommendation'))
                ? unreadMessage.recommendation
                : false,
            timestamp: unreadMessage.timestamp,
            type: unreadMessage.type
        });
    },

    addUserMessage (state, message) {
        state.messages.push({
            stationId: message.stationId,
            originator: 'user',
            content: message.content,
            timestamp: message.timestamp,
            type: 'webtext'
        });

        // @event user-message-added
        emitter.emit('user-message-added', message);
    },

    addNewsMessage (state, news) {
        state.news.push({
            content: news.content,
            timestamp: news.timestamp,
            type: news.type
        });
    },

    deleteNewsMessages (state) {
        state.news = [];
    },

    isWriting (state, isWriting) {
        state.isWriting = isWriting;
    },

    markAllMessagesAsRead (state) {
        state.messages = state.messages.concat(state.unreadMessages);
        state.unreadMessages = [];
    },

    markMessagesAsReadForStation (state, stationId) {
        let unreadMessages =
            state.unreadMessages
                .filter((item) => {
                    return (item.stationId == stationId);
                });

        // Delete from `unreadMessages`
        state.unreadMessages =
            state.unreadMessages
                .filter((item) => {
                    return (item.stationId != stationId);
                });

        // Add to read messages
        state.messages = state.messages.concat(unreadMessages);

        // @event unread-message-count-changed
        emitter.emit('unread-message-count-changed', state.unreadMessages.length);
    },

    deleteMessagesForStation (state, stationId) {
        // Delete unread messages
        state.unreadMessages =
            state.unreadMessages
                .filter((item) => {
                    return (item.stationId != stationId);
                });

        // Delete read messages
        state.messages =
            state.messages
                .filter((item) => {
                    return (item.stationId != stationId);
                });

        // @event unread-message-count-changed
        emitter.emit('unread-message-count-changed', state.unreadMessages.length);
    },

    addDelayedMessage (state, delayedMessage) {
        state.delayedMessages.push(delayedMessage);
    },

    deleteDelayedMessage (state, stationId) {
        state.delayedMessages =
            state.delayedMessages
                .filter((item) => {
                    return (item.stationId != stationId);
                });
    },

    addDelayedMessageTimeout (state, delayedMessageTimeout) {
        state.delayedMessageTimeouts.push(delayedMessageTimeout);
    },

    deleteDelayedMessageTimeouts (state, stationId) {
        if (state.delayedMessageTimeouts.length) {
            let remainingTimeouts = [];

            state.delayedMessageTimeouts.forEach((data) => {
                if (stationId == data.stationId) {
                    clearTimeout(data.timeout);
                } else {
                    remainingTimeouts.push(data);
                }
            });

            state.delayedMessageTimeouts = remainingTimeouts;
        }
    },

    deleteDelayedMessageTimeout (state, timeout) {
        state.delayedMessageTimeouts =
            state.delayedMessageTimeouts
                .filter((item) => {
                    return (item.timeout != timeout);
                });
    },
};

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
};
