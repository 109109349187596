<!--
/**
 * Video message template
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { reactive, defineProps, computed } from 'vue';
    import { getAssetPathForVideos } from '?/Composable/Tools';
    import VideoPlayer from '?/Component/Library/VideoPlayer';
    import VideoEventModel from '?/Model/VideoEventModel';
    import repository from '?/Composable/Repository';

    // Data
    const props = defineProps({
        message: {
            type: Object,
            required: true
        }
    });

    const state = reactive({
        baseAssetPath: getAssetPathForVideos()
    });

    const events = computed(() => {
        let e = [];

        // Medium seen event
        if (props.message.seenRatio) {
            let seenThreshold = parseFloat(props.message.seenRatio);
            let customEvent = new VideoEventModel();

            customEvent.eventName = 'timeupdate';
            customEvent.stepInPercent = (seenThreshold * 100);
            customEvent.repeatable = false;
            customEvent.callback = (e) => {
                repository.sendMediumProgressToStation(
                    props.message.stationId,
                    props.message.contentRef,
                    seenThreshold
                );
            };

            e.push(customEvent);
        }

        return e;
    });
</script>

<template>
    <VideoPlayer :src="state.baseAssetPath + '/' + message.content"
                 :events="events" />
</template>
