<!--
/**
 * Single email template (activation only, like story)
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { reactive, computed, onMounted } from 'vue';
    import { useRoute } from 'vue-router';
    import store from '?/Stores';
    import repository from '?/Composable/Repository';
    import { email as emailData } from '?/ProjectData';
    import { getAssetPath } from '?/Composable/Tools';
    import { t } from '?/Composable/Translation';

    // Initialization
    const route = useRoute();

    // Data
    const props = defineProps({
        station: {
            type: Object,
            required: true
        }
    });

    const slug = computed(() => {
         return route.meta.slug;
    });

    const state = reactive({
        station: props.station,
        stationVisible: false
    });

    // Custom setup logic
    onMounted(() => {
        state.stationVisible = props.station.visible;

        if (!props.station.done) {
            repository.activateStation(props.station.stationId);
        }
    });

    // Functions
    const data = (id) => {
        return emailData.find((data) => {
            return data.id == id;
        });
    };
</script>

<template>
    <div id="station-email-detail"
         class="station-email-detail station-type__activation-only"
         v-if="state.stationVisible">
        <div class="email"
             :id="'email-' + state.station.stationId">
            <figure class="email-author-image">
                <span class="email-author-image-wrapper"
                      :style="'background-image: url(\'' + getAssetPath() + '/' + slug + '/author-' + data(state.station.stationId).author + '.png\');'">
                    <img :src="getAssetPath() + '/' + slug + '/author-' + data(state.station.stationId).author + '.png'"
                         alt="" />
                </span>
                <figcaption>
                    <h3 class="email-author"
                        v-html="t('email.author-' + data(state.station.stationId).author)"></h3>
                    <div class="email-subject"
                         v-html="data(state.station.stationId).subject"></div>
                </figcaption>
            </figure>
            <div class="email-message"
                 v-html="data(state.station.stationId).message"></div>
        </div>
    </div>
    <div id="station-email-detail" class="station-email-detail" v-else>
        {{ $t('email.unavailable') }}
    </div>
</template>
