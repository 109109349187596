/**
 * Provide CIMMS connection (e.g. SSE)
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */

import config from '?/Config';
import constants from '?/Constants';
import Cimms from 'cimms';
import store from '?/Stores';
import emitter from '?/Composable/Events/Emitter';
import { t } from '?/Composable/Translation';
import { calculateDelay } from '?/Composable/Tools';

// Initialization
let repository;

export default {

    /**
     * Prepare CIMMS connection
     *
     * @return {object}
     */
    prepare () {
        repository = new Cimms({
            baseUrlUserAccount: config.baseUrlUserAccount,
            baseUrlWebapp: config.baseUrlWebapp,
            projectId: config.projectId,
            storyId: config.storyId,
            apiVersion: config.apiVersion || 1
        });

        // Expose CIMMS instance
        if (config.debug) {
            window.cimms = repository;

            console.log(
                'CIMMS instance as `window.cimms` exposed: ', repository
            );
        }

        return repository;
    },

    /**
     * Establish CIMMS connection (SSE)
     *
     * @return {object}
     */
    connect () {
        // Dismiss if not authenticated
        if (!repository.config.isAuthenticated()) {
            return repository;
        }

        // Start SSE connection
        repository.event
            .startStreaming()
            .addEventListenerPlayerstatus((playerstatus) => {
                store.commit('activeStations', playerstatus.activeStations);
                store.dispatch('changeCurrentStationId', playerstatus.currentStation);
                store.commit('currentStoryId', playerstatus.currentStory);
                store.dispatch('checkUnseenStationCount', playerstatus.station);
                store.commit('stations', playerstatus.station);
                store.commit('stories', playerstatus.story);
                store.commit('totalPoints', playerstatus.totalPoints);
                store.commit('hasTeam', playerstatus.hasTeam);

                // @event sse-playerstatus
                emitter.emit('sse-playerstatus', playerstatus);

                if (config.debug) {
                    console.log(
                        'SSE API event `playerstatus`: ', playerstatus
                    );
                }
            })
            .addEventListenerWebtext((webtext) => {
                let stationType = store.getters.station(webtext.stationId).style;

                switch (stationType) {
                    case 'game-station':
                        store.dispatch(
                            'addUnreadMessageDelayed',
                            {
                                content: webtext,
                                delay: calculateDelay(webtext.text)
                            }
                        );

                        break;
                    default:
                        store.commit('addMessage', webtext);
                };

                // @event sse-webtext
                emitter.emit('sse-webtext', webtext);

                if (config.debug) {
                    console.log(
                        'SSE API event `webtext`: ', webtext
                    );
                }
            })
            .addEventListenerWebcontent((webcontent) => {
                let stationType = store.getters.station(webcontent.stationId).style;

                switch (stationType) {
                    case 'game-station':
                        store.dispatch(
                            'addUnreadMessageDelayed',
                            {
                                content: webcontent,
                                delay: constants.defaultUnreadMessageDelay
                            }
                        );

                        break;
                    default:
                        store.commit('addMessage', webcontent);
                };

                // @event sse-webcontent
                emitter.emit('sse-webcontent', webcontent);

                if (config.debug) {
                    console.log(
                        'SSE API event `webcontent`: ', webcontent
                    );
                }
            })
            .addEventListenerButtongroupStart((buttongroupStart) => {
                store.dispatch('resetButtonsforStation', buttongroupStart.stationId);

                // @event sse-buttoninputExpected
                emitter.emit('sse-buttongroupStart', buttongroupStart);

                if (config.debug) {
                    console.log(
                        'SSE API event `buttongroupStart`: ', buttongroupStart
                    );
                }
            })
            .addEventListenerWebbutton((webbutton) => {
                store.commit('addButtonToStation', webbutton);

                // @event sse-webbutton
                emitter.emit('sse-webbutton', webbutton);

                if (config.debug) {
                    console.log(
                        'SSE API event `webbutton`: ', webbutton
                    );
                }
            })
            .addEventListenerAnswerExpected((answerExpected) => {
                store.commit('addStationToAnswerExpected', answerExpected.stationId);

                // Reset answer rejected trigger
                store.commit('deleteStationFromAnswerRejected', answerExpected.stationId);

                // @event sse-answerExpected
                emitter.emit('sse-answerExpected', answerExpected);

                if (config.debug) {
                    console.log(
                        'SSE API event `answerExpected`: ', answerExpected
                    );
                }
            })
            .addEventListenerAnswerRejected((answerExpected) => {
                store.dispatch('answerRejectedforStationDelayed', answerExpected.stationId);

                // @event sse-answerRejected
                emitter.emit('sse-answerRejected', answerExpected);

                if (config.debug) {
                    console.log(
                        'SSE API event `answerRejected`: ', answerExpected
                    );
                }
            })
            .addEventListenerButtoninputExpected((buttoninputExpected) => {
                store.commit('addStationToButtonInputExpected', buttoninputExpected.stationId);

                // @event sse-buttoninputExpected
                emitter.emit('sse-buttoninputExpected', buttoninputExpected);

                if (config.debug) {
                    console.log(
                        'SSE API event `buttoninputExpected`: ', buttoninputExpected
                    );
                }
            })
            .addEventListenerWebcontentRecommendation((webcontentRecommendation) => {
                // @event sse-webcontentRecommendation
                emitter.emit('sse-webcontentRecommendation', webcontentRecommendation);

                if (config.debug) {
                    console.log(
                        'SSE API event `webcontentRecommendation`: ', webcontentRecommendation
                    );
                }
            })
            .addEventListenerWebtextRecommendation((webtextRecommendation) => {
                // @event sse-webtextRecommendation
                emitter.emit('sse-webtextRecommendation', webtextRecommendation);

                if (config.debug) {
                    console.log(
                        'SSE API event `webtextRecommendation`: ', webtextRecommendation
                    );
                }
            })
            .addEventListenerError((error) => {
                // @event sse-error
                emitter.emit('sse-error', error);

                if (repository.event.eventStream.readyState === 2) {
                    store.commit('flashMessageReload', true);
                    store.commit('flashMessage', t('apiError.sse'));
                }

                if (config.debug) {
                    console.log(
                        'SSE API event `error`: ', error
                    );
                }
            });

        // Reset flash message
        store.commit('flashMessageReload', false);
        store.commit('flashMessage', null);

        // Stop streaming
        window.addEventListener('beforeunload', () => {
            repository.event.stopStreaming();
        });

        return repository;
    },

     /**
     * Close CIMMS connection (SSE)
     *
     * @return {object}
     */
    close () {
        repository.event.stopStreaming();
    },

    /**
     * Get event stream
     *
     * @return {EventSource}
     */
    getEventStream() {
        return repository.event.eventStream;
    },

    /**
     * Is user authenticated
     *
     * @return {null|string}
     */
    isAuthenticated () {
        return repository.config.isAuthenticated();
    },

    /**
     * Registration with phone number as identifier
     *
     * @param {string} phone     Phone number
     * @param {string} firstName First name
     *
     * @return {Promise}
     */
    registrationPhone (phone, firstName) {
        return repository.userAccount.registrationPhone(phone, firstName);
    },

    /**
     * Anonymous registration
     *
     * @return {Promise}
     */
    registrationAnon () {
        return repository.userAccount.registrationAnon()
            .then((response) => {
                store.commit('authenticated', repository.config.isAuthenticated());
                return response;
            });
    },

    /**
     * Verification with phone number as identifier
     *
     * @param {string} phone   Phone number
     * @param {string} smsCode Verification token send by SMS
     *
     * @return {Promise}
     */
    verificationPhone (phone, smsCode) {
        return repository.userAccount.verificationPhone(phone, smsCode);
    },

    /**
     * Authenticate user by phone
     *
     * @param {string} identifier Phone number
     * @param {string} secret     User password
     *
     * @return {Promise}
     */
    authenticationPhone (identifier, secret) {
        return repository.userAccount.authenticationPhone(identifier, secret)
            .then((response) => {
                store.commit('authenticated', repository.config.isAuthenticated());
                return response;
            });
    },

    /**
     * Logout
     *
     * @return {Promise}
     */
    logout () {
        return repository.userAccount.logout();
    },

    /**
     * Request password reset with phone number
     *
     * @param {string} phone Phone number
     *
     * @return {Promise}
     */
    requestPasswordResetPhone (phone) {
        return repository.userAccount.requestPasswordResetPhone(phone);
    },

    /**
     * Change password
     *
     * @param {string} newPassword New password
     * @param {string} token       Token to authenticate change
     *
     * @return {Promise}
     */
    changePassword (newPassword, token) {
        return repository.userAccount.changePassword(newPassword, token);
    },

    /**
     * Send user answer (text)
     *
     * @param {int}    stationId     ID of station to reply to
     * @param {string} answerContent Text reply of user
     *
     * @return {Promise}
     */
    sendTextAnswerToStation (stationId, answerContent) {
        return repository.userReply.sendTextAnswerToStation(stationId, answerContent)
            .catch((error) => {
                store.commit('flashMessage', t('apiError.' + error.code));

                throw error;
            });
    },

    /**
     * Send reply to station via button
     *
     * @param {int}    stationId ID of station to reply to
     * @param {string} action    Type of action/button
     *
     * @return {Promise}
     */
    sendButtonAnswerToStation (stationId, action) {
        return repository.userReply.sendButtonAnswerToStation(stationId, action)
            .catch((error) => {
                store.commit('flashMessage', t('apiError.' + error.code));

                throw error;
            });
    },

    /**
     * Send medium progress to station
     *
     * @param {int}    stationId     ID of station to reply to
     * @param {string} contentRef    Reference ID of content
     * @param {float}  progressRatio Progress
     *
     * @return {Promise}
     */
    sendMediumProgressToStation (stationId, contentRef, progressRatio) {
        return repository.userReply.sendMediumProgressToStation(
            stationId,
            contentRef,
            progressRatio
        );
    },

    /**
     * Activate station
     *
     * @param {int}    stationId Station id
     *
     * @return {Promise}
     */
    activateStation (stationId) {
        return repository.station.activate(stationId)
            .catch((error) => {
                store.commit('flashMessage', t('apiError.' + error.code));

                throw error;
            });
    },

    /**
     * Cancel station
     *
     * @param {int}    stationId Station id
     *
     * @return {Promise}
     */
    cancelStation (stationId) {
        return repository.station.cancel(stationId)
            .catch((error) => {
                store.commit('flashMessage', t('apiError.' + error.code));

                throw error;
            });
    },

    /**
     * Activate story
     *
     * @param {int} stationId ID of station to reply to
     *
     * @return {Promise}
     */
    activateStory (stationId) {
        return repository.story.activate(stationId)
            .catch((error) => {
                store.commit('flashMessage', t('apiError.' + error.code));

                throw error;
            });
    },

    /**
     * Activate outro (delete account)
     *
     * @param {int} skip Skip outro or not
     *
     * @return {Promise}
     */
    activateOutro (skip) {
        return repository.outro.activate(skip);
    },

    /**
     * Generate team invite
     *
     * @return {Promise}
     */
    generateTeamInvite (skip) {
        return repository.team.teamInvite()
            .catch((error) => {
                store.commit('flashMessage', t('apiError.team.' + error.code));

                throw error;
            });
    },

    /**
     * Join team
     *
     * @param {string} invite Invite token
     *
     * @return {Promise}
     */
    joinTeam (invite) {
        return repository.team.teamJoin(invite);
    },

    /**
     * Get auth token
     *
     * @return {String}
     */
    getAuthToken () {
        return repository.config.authToken;
    },

    /**
     * Set auth token
     *
     * @param {string} token Auth token
     *
     * @return {Object}
     */
    setAuthToken (token) {
        repository.config.authToken = token;

        return this;
    }

};
