<!--
/**
 * Flash message component
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { useStore } from 'vuex';

    // Initialization
    const store = useStore();

    // Methods
    function close () {
        if (store.getters.flashMessageReload === true) {
            store.commit('flashMessageReload', null);
            window.location.reload();
        }

        store.commit('flashMessage', null);

        return false;
    };
</script>

<template>
    <transition name="flash-message-fade">
        <div class="flash-message-wrapper" v-if="store.getters.flashMessage">
            <div class="flash-message">
                <p>{{ store.getters.flashMessage }}</p>
            </div>
            <div class="flash-message-actions">
                <button type="button"
                        class="btn"
                        :class="(store.getters.flashMessageReload === true) ? 'btn-reload' : 'btn-close'"
                        @click="close"
                        :aria-label="(store.getters.flashMessageReload) ? $t('modal.reload') : $t('modal.close')"
                        v-html="(store.getters.flashMessageReload) ? $t('modal.reload') : $t('modal.close')"></button>
            </div>
        </div>
    </transition>
</template>

<style scoped>
    .flash-message-fade-enter,
    .flash-message-fade-leave-to {

        opacity: 0;

    }

    .flash-message-fade-enter-active,
    .flash-message-fade-leave-active {

        transition: opacity .5s ease;

    }
</style>
