<!--
/**
 * Audio player component
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { reactive, onDeactivated } from 'vue';

    // Get parent data
    const props = defineProps({
        src: {
            type: String,
            required: true
        },
        prePlayedLabel: {
            type: String,
            required: false,
            default: null
        },
        range: {
            type: Boolean,
            required: false,
            default: true
        },
        timer: {
            type: Boolean,
            required: false,
            default: true
        },
        events: {
            type: Array,
            required: false,
            default: []
        }
    });

    // Data
    const state = reactive({
        audio: null,
        src: props.src,
        prePlayedLabel: props.prePlayedLabel,
        showRange: props.range,
        showTimer: props.timer,
        canPlay: false,
        canPlayThrough: false,
        playbackTime: 0,
        audioDuration: null,
        isPlaying: false,
        wasPlaying: null,
        timeLeft: '00:00',
        played: false
    });

    // Methods
    const convertSecondsToMMSS = (secs) => {
        secs = parseInt(secs, 10);
        let minutes = Math.floor(secs / 60) % 60;
        let seconds = secs % 60;

        return [minutes, seconds]
            .map(v => (v < 10) ? '0' + v : v)
            .join(':')
    };

    const refreshAudioTime = () => {
        let audioLength = state.audio.duration - state.audio.currentTime;
        state.timeLeft = convertSecondsToMMSS(audioLength);
    };

    const getCustomEvents = (eventName) => {
        let customEvents = [];

        if (props.events.length) {
            props.events.forEach((event) => {
                if (event.eventName === eventName) {
                    customEvents.push(event);
                }
            });
        }

        return customEvents;
    };

    const loadedMetaData = () => {
        state.canPlay = true;
        state.audioDuration = state.audio.duration;

        refreshAudioTime();

        getCustomEvents('loadedmetadata').forEach((event) => {
            event.callback();
        });
    };

    const canPlayThrough = () => {
        state.canPlayThrough = true;

        getCustomEvents('canplaythrough').forEach((event) => {
            event.callback();
        });
    };

    const canPlay = () => {
        state.canPlay = true;

        getCustomEvents('canplay').forEach((event) => {
            event.callback();
        });
    };

    const timeUpdate = () => {
        if (!state.isPlaying || !state.audio) {
            return;
        }

        state.playbackTime = state.audio.currentTime;

        refreshAudioTime();

        const currentTime = Math.round(state.audio.currentTime);
        const progressInPercent = Math.round(state.audio.currentTime * 100 / state.audioDuration);

        getCustomEvents('timeupdate').forEach((event) => {
            // @NOTE: Could fire multiple times due to rounding for integer
            if (
                (event.time && currentTime >= event.time) ||
                (!isNaN(event.stepInPercent) && progressInPercent >= event.stepInPercent)
            ) {
                event.callback();
            }
        });
    };

    const ended = () => {
        state.isPlaying = false;

        getCustomEvents('ended').forEach((event) => {
            event.callback();
        });
    };

    const pause = () => {
        state.isPlaying = false;

        getCustomEvents('pause').forEach((event) => {
            event.callback();
        });
    };

    const play = () => {
        state.isPlaying = true;
        state.played = true;

        getCustomEvents('play').forEach((event) => {
            event.callback();
        });
    };

    const onClickPlayButton = () => {
        if (!state.isPlaying) {
            state.audio.play();
        } else {
            state.audio.pause();
        }

        state.wasPlaying = null;
    };

    const onInputCurrentAudioTime = (event) => {
        if (state.wasPlaying === null) {
            state.wasPlaying = state.isPlaying;
        }

        if (state.isPlaying) {
            state.isPlaying = false;
            state.audio.pause();
        }

        state.audio.currentTime = event.target.value;
        state.playbackTime = state.audio.currentTime;
    };

    const onChangeCurrentAudioTime = (event) => {
        state.audio.currentTime = event.target.value;
        state.playbackTime = state.audio.currentTime;

        if (state.wasPlaying) {
            state.wasPlaying = null;
            state.audio.play();
        }
    };

    // Stop playback, when leaving component
    onDeactivated(() => {
        state.isPlaying = false;
    });
</script>

<template>
    <div class="audio-player-wrapper"
         :class="{ 'has-timer': state.showTimer }">
        <audio :ref="(el) => { state.audio = el }"
               :src="state.src"
               @loadedmetadata="loadedMetaData"
               @canplaythrough="canPlayThrough"
               @canplay="canPlay"
               @timeupdate="timeUpdate"
               @ended="ended"
               @pause="pause"
               @play="play"
               @waiting="state.isPlaying = false"
               @playing="state.isPlaying = true"></audio>

        <div class="audio-player-controls">
            <button @click="onClickPlayButton"
                    class="audio-player-controls-button"
                    :class="(!state.isPlaying) ? 'start' : 'pause'"
                    :disabled="!state.canPlay">
                <span class="audio-player-controls-button-label">
                    <template v-if="!state.isPlaying">▶</template>
                    <template v-if="state.isPlaying">■</template>
                </span>
            </button>

            <div class="audio-player-pre-played-label"
                 v-if="state.prePlayedLabel && !state.played"
                 v-html="state.prePlayedLabel"
                 @click="onClickPlayButton"></div>

            <div class="audio-player-controls-slider-wrapper"
                 v-if="state.showRange && (!state.prePlayedLabel || (state.prePlayedLabel && state.played))">
                <progress class="audio-player-controls-progess"
                          aria-hidden="true"
                          :value="state.playbackTime"
                          :max="state.audioDuration"></progress>
                <input
                    class="audio-player-controls-slider"
                    type="range"
                    step="0.1"
                    min="0"
                    :value="state.playbackTime"
                    :max="state.audioDuration"
                    :disabled="!state.canPlayThrough"
                    @input="onInputCurrentAudioTime"
                    @change="onChangeCurrentAudioTime"
                />
            </div>

        </div>

        <div class="audio-player-time"
             v-if="state.showTimer && (!state.prePlayedLabel || (state.prePlayedLabel && state.played))"
             v-html="state.timeLeft"></div>
    </div>
</template>
