/**
 * Video event model class
 *
 * Usage:
 *
 * let customEvent = new VideoEventModel();
 *
 * customEvent.eventName = 'timeupdate';
 * customEvent.time = 5; // seconds
 * customEvent.repeatable = false;
 * customEvent.callback = (e) => {
 *     console.log('Fired');
 * };
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */

import MediaEventModel from '?/Model/MediaEventModel';


export default class VideoEventModel extends MediaEventModel
{

}
