/**
 * Translation and preparation of Vue I18n
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */

import { createI18n } from 'vue-i18n';
import { languages } from '?/Languages';
import { defaultLocale } from '?/Languages';

const messages = Object.assign(languages);

export const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: defaultLocale,
    fallbackLocale: 'en',
    messages
});

export const { t } = i18n.global;
