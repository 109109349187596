<!--
/**
 * Webapp team invite template
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import config from '?/Config';
    import store from '?/Stores';
    import repository from '?/Composable/Repository';
    import { routeTo } from '?/Composable/Tools';
    import { t } from '?/Composable/Translation';
    import emitter from '?/Composable/Events/Emitter';
    import ShareModal from '?/Component/ModalContent/Share';

    // Functions
    const generateInvite = () => {

        repository.generateTeamInvite()
            .then((response) => {
                // let inviteUrl = (config.baseUrl)
                //     ? config.baseUrl
                //     : window.location.origin;
                //
                // inviteUrl += '/webapp/?tjr=' + response.invite;

                // @event activate-modal
                emitter.emit('activate-modal', {
                    component: ShareModal,
                    title: t('team.invite'),
                    data: {
                        shareSheetTitle: t('team.invite-share-title'),
                        shareSheetText: t('team.invite-share-text'),
                        text: response.invite
                        // url: inviteUrl
                    }
                });

                // @FIXME Remove if API updates `playerstatus` after invite
                store.commit('hasTeam', true);
            });
    };
</script>

<template>
    <div class="team-invite"
         v-if="store.getters.isAuthenticated && config.allowTeams">
        <p v-html="$t('team.invite-description')"></p>
        <button class="team-invite-button"
                @click="generateInvite()">
            <template v-if="!store.getters.hasTeam">{{ $t('team.generate-invite') }}</template>
            <template v-else>{{ $t('team.show-invite') }}</template>
        </button>
    </div>
</template>
