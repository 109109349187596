<!--
/**
 * Modal component for image content
 *
 * Usage:
 *
 * ```
 * import ImageModal from '?/Component/ModalContent/Image';
 *
 * // @event activate-modal
 * emitter.emit('activate-modal', {
 *     component: ImageModal,
 *     title: 'Test Title',
 *     defaultCloseButton: false,
 *     data: {
 *         image: '<img src="/path/to/image.jpg" alt="" />'
 *     }
 * });
 * ```
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { defineProps } from "vue";

    const props = defineProps({
        data: {
            type: Object,
            required: true
          }
    });
</script>

<template>
    <div class="image-wrapper"
         :class="{'zoomable': props.data.zoom}"
         v-html="props.data.image"></div>
</template>
