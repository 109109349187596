<!--
/**
 * Knights list template
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>

</script>

<template>
    <div id="knights" class="knights-wrapper">
        <router-link to="/webapp/knight/rheinhardt" class="knight knight-rheinhardt">
            Rheinhard
        </router-link>
        <router-link to="/webapp/knight/adelhild" class="knight knight-adelhild">
            Adelhild
        </router-link>
        <router-link to="/webapp/knight/lorelei" class="knight knight-lorelei">
            Lorelei
        </router-link>
        <router-link to="/webapp/knight/jupp" class="knight knight-jupp">
            Jupp
        </router-link>
        <router-link to="/webapp/knight/edana" class="knight knight-edana">
            Edana
        </router-link>
        <router-link to="/webapp/knight/loretta" class="knight knight-loretta">
            Loretta
        </router-link>
        <router-link to="/webapp/knight/franz" class="knight knight-franz">
            Franz
        </router-link>
        <router-link to="/webapp/knight/heinrich" class="knight knight-heinrich">
            Heinrich
        </router-link>
        <router-link to="/webapp/knight/leander" class="knight knight-leander">
            Leander
        </router-link>
        <router-link to="/webapp/knight/zita" class="knight knight-zita">
            Zita
        </router-link>
        <router-link to="/webapp/knight/balduin" class="knight knight-balduin">
            Balduin
        </router-link>
    </div>
</template>

