<!--
/**
 * Video player component
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { reactive, onDeactivated } from 'vue';

    // Get parent data
    const props = defineProps({
        src: {
            type: String,
            required: true
        },
        events: {
            type: Array,
            required: false,
            default: []
        }
    });

    // Data
    const state = reactive({
        video: null,
        src: props.src,
        canPlay: false,
        canPlayThrough: false,
        playbackTime: 0,
        videoDuration: null,
        isPlaying: false,
        wasPlaying: null,
        timeLeft: '00:00'
    });

    // Methods
    const convertSecondsToMMSS = (secs) => {
        secs = parseInt(secs, 10);
        let minutes = Math.floor(secs / 60) % 60;
        let seconds = secs % 60;

        return [minutes, seconds]
            .map(v => (v < 10) ? '0' + v : v)
            .join(':')
    };

    const refreshVideoTime = () => {
        let videoLength = state.video.duration - state.video.currentTime;
        state.timeLeft = convertSecondsToMMSS(videoLength);
    };

    const getCustomEvents = (eventName) => {
        let customEvents = [];

        if (props.events.length) {
            props.events.forEach((event) => {
                if (event.eventName === eventName) {
                    customEvents.push(event);
                }
            });
        }

        return customEvents;
    };

    const loadedMetaData = () => {
        state.canPlay = true;
        state.videoDuration = state.video.duration;

        refreshVideoTime();

        getCustomEvents('loadedmetadata').forEach((event) => {
            event.callback();
        });
    };

    const canPlayThrough = () => {
        state.canPlayThrough = true;

        getCustomEvents('canplaythrough').forEach((event) => {
            event.callback();
        });
    };

    const canPlay = () => {
        state.canPlay = true;

        getCustomEvents('canplay').forEach((event) => {
            event.callback();
        });
    };

    const timeUpdate = () => {
        if (!state.isPlaying || !state.video) {
            return;
        }

        state.playbackTime = state.video.currentTime;

        refreshVideoTime();

        const currentTime = Math.round(state.video.currentTime);
        const progressInPercent = Math.round(state.video.currentTime * 100 / state.videoDuration);

        getCustomEvents('timeupdate').forEach((event) => {
            // @NOTE: Could fire multiple times due to rounding for integer
            if (
                (event.time && currentTime >= event.time) ||
                (!isNaN(event.stepInPercent) && progressInPercent >= event.stepInPercent)
            ) {
                event.callback();
            }
        });
    };

    const ended = () => {
        state.isPlaying = false;

        getCustomEvents('ended').forEach((event) => {
            event.callback();
        });
    };

    const pause = () => {
        state.isPlaying = false;

        getCustomEvents('pause').forEach((event) => {
            event.callback();
        });
    };

    const play = () => {
        state.isPlaying = true;

        getCustomEvents('play').forEach((event) => {
            event.callback();
        });
    };

    const onClickPlayButton = () => {
        if (!state.isPlaying) {
            state.video.play();
        } else {
            state.video.pause();
        }

        state.wasPlaying = null;
    };

    const onInputCurrentvideoTime = (event) => {
        if (state.wasPlaying === null) {
            state.wasPlaying = state.isPlaying;
        }

        if (state.isPlaying) {
            state.isPlaying = false;
            state.video.pause();
        }

        state.video.currentTime = event.target.value;
        state.playbackTime = state.video.currentTime;
    };

    const onChangeCurrentvideoTime = (event) => {
        state.video.currentTime = event.target.value;
        state.playbackTime = state.video.currentTime;

        if (state.wasPlaying) {
            state.wasPlaying = null;
            state.video.play();
        }
    };

    // Stop playback, when leaving component
    onDeactivated(() => {
        state.isPlaying = false;
    });
</script>

<template>
    <div class="video-player-wrapper">
        <video :ref="(el) => { state.video = el }"
               :src="state.src"
               @loadedmetadata="loadedMetaData"
               @canplaythrough="canPlayThrough"
               @canplay="canPlay"
               @timeupdate="timeUpdate"
               @ended="ended"
               @pause="pause"
               @play="play"
               @waiting="state.isPlaying = false"
               @playing="state.isPlaying = true"
               controls></video>
    </div>
</template>
