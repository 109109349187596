/**
 * Prepare registration entry point
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */

import { createApp, reactive } from 'vue';
import config from '?/Config';
import store from '?/Stores';
import { createI18n } from 'vue-i18n';
import repository from '?/Composable/Repository';
import emitter from '?/Composable/Events/Emitter';
import { i18n, t } from '?/Composable/Translation';

import registerEvents from '?/Composable/Events/Registration';
import registerCustomEvents from '?/Composable/Events/CustomRegistration';
import RegistrationForm from '?/Component/Registration/RegistrationForm';
import LoginForm from '?/Component/LoginForm';
import Modal from '?/Component/Modal';
import FlashMessage from '?/Component/FlashMessage';
import LostPasswordModal from '?/Component/ModalContent/LostPassword';
import OptInModal from '?/Component/ModalContent/OptIn';

// Create app
let app = createApp({
    setup() {
        registerEvents();
        registerCustomEvents();

        // Data
        const state = reactive({
            config: config,
            authenticated: repository.isAuthenticated(),
            consentYouTube: null,
            footerSectionOpened: null
        });

        return {state};
    },
    methods: {
        openLostPasswordModal: function () {
            // @event activate-modal
            emitter.emit('activate-modal', {
                component: LostPasswordModal,
                title: t('account.lost-password')
            });
        },
        openOptInModal: function () {
            // @event activate-modal
            emitter.emit('activate-modal', {
                component: OptInModal,
                title: t('account.opt-in')
            });
        }
    },
    components: {
        'registration-form': RegistrationForm,
        'login-form': LoginForm,
        'modal': Modal,
        'flash-message': FlashMessage
    }
});

// Provide global variables
app.provide('repository', repository.prepare());
app.provide('emitter', emitter);

// Mount app
app
    .use(store)
    .use(i18n)
    .mount('#app');
