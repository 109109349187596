/**
 * Register events for registration
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */

import { inject, onMounted } from 'vue';
import config from '?/Config';
import { t } from '?/Composable/Translation';
import ChangePasswordModal from '?/Component/ModalContent/ChangePassword';
import emitter from '?/Composable/Events/Emitter';
import store from '?/Stores';

export default function registerEvents() {
    /*
     * Route to webapp
     */
    emitter.on('*', (e, authToken) => {
        if (['login-is-authenticated', 'opt-in-successful'].includes(e)) {
            if (config.debug) {
                console.log(
                    'Register event: ', e,
                    'Action: ', 'Route to webapp'
                );
            }

            const appendix = (['opt-in-successful'].includes(e))
                ? 'intro/'
                : '';

            window.location.replace('webapp/' + appendix);
        }
    });

    // URL events

    /*
     * Open change password modal
     */
    onMounted(() => {
        const token = (new URL(document.location)).searchParams.get('change_password');
        const deletedAccount = (new URL(document.location)).searchParams.get('account-deleted');

        if (token) {
            // @event activate-modal
            emitter.emit('activate-modal', {
                component: ChangePasswordModal,
                title: t('account.change-password'),
                data: {
                    token: token
                }
            });

        }

        if (deletedAccount) {
            store.commit('flashMessage', t('account.deleted'));
        }
    });

};
