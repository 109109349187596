<!--
/**
 * Privacy template
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { reactive } from 'vue';
    import { privacy } from '?/ProjectData';
    import store from '?/Stores';

    // Data
    const state = reactive({
        privacy: privacy
    });
</script>

<template>
    <div class="privacy">
        <div class="content-wrapper">
            <h2>Datenschutzinformationen</h2>
            <p>
                Bei diesem Gratisangebot handelt es sich um ein interaktives Spiel für Familien, das in einer WebApp stattfindet.
            </p>
            <p>
                Verantwortlicher im datenschutzrechtlichen Sinne ist die Rheinland-Pfalz Tourismus GmbH vertreten durch den Geschäftsführer Stefan Zindler, Löhrstr. 103-105, 56068 Koblenz, Fon: 0261-915200, Fax: 0261-9152040, E-Mail: info@rlp-tourismus.de
            </p>
            <p>
                Der Datenschutzbeauftragte ist zu erreichen unter datenschutz@m-consecom.de.
            </p>
            <h3>Welche Daten erfassen wir von Ihnen?</h3>
            <p>
                Grundsätzlich erfassen wir nur solche personengebundenen Daten, die für die Durchführung des interaktiven Angebots nötig sind und löschen sie wieder, sobald die Durchführung beendet ist.
            </p>
            <p>
                Die Zulässigkeit dieser Verarbeitung richtet sich nach Art. 6 Abs. 1 b) DS-GVO, wonach die Verarbeitung rechtmäßig ist, wenn sie für die Erfüllung eines Vertrags, dessen Vertragspartei die betroffene Person ist, oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist, die auf Anfrage der betroffenen Person erfolgen. Die Bereitstellung der Daten ist erforderlich, um an dem Angebot teilnehmen zu können.
            </p>
            <p>
                Wir nutzen keine Cookies. Um Sie als User zu identifizieren und Ihre Einstellungen zu speichern, nutzen wir automatisiert lediglich den Browserspeicher. IP-Adressen erfassen wir wenn überhaupt nur anonymisiert.
            </p>
            <p>
                Wir übermitteln und speichern keine Standortdaten. Wenn Sie die Standorterkennung in Ihrem Mobilgerät aktivieren, werden Empfehlungen, welche Stationen in Ihrer Nähe verfügbar sind, in ihrem Browser generiert und nicht übermittelt.
            </p>

            <h3>Wofür nutzen wir Ihre Daten?</h3>
            <p>
                Ausschließlich zur Durchführung des von Ihnen gewählten interaktiven Angebots.
            </p>
            <p>
                In besonderen Fällen können Daten auch im gesetzlich vorgesehenen Rahmen zur Auskunftserteilung gegenüber staatlichen Stellen sowie zur Missbrauchserkennung herangezogen werden.
            </p>
            <h3>Wie können Sie Ihre Daten löschen?</h3>
            <p>
                Sie können Ihre Daten jederzeit löschen, indem Sie in Ihrem Nutzer-Account das Menü öffnen und &gt;&gt; Account Löschen &lt;&lt; wählen.
            </p>
            <p>
                Eine automatische Löschung Ihrer Daten erfolgt spätestens, wenn Sie zwei Woche nicht aktiv waren.
            </p>
            <p>
                Sie können sich auch direkt an uns wenden, die Kontaktdaten finden Sie <a href="https://www.rlp-tourismus.com/de/impressum" target="_blank">hier</a>. Neben der Löschung können Sie sich auch bezüglich Ihres Rechts auf Auskunft, Berichtigung, Sperrung und Widerspruch an uns wenden.
            </p>

            <p>
                Zur Durchführung des Angebots bedient sich der Verantwortliche der A.R.S. – Alternate Reality Strategies GmbH, Lützner Str. 85, 04177 Leipzig. Die datenschutzrechtlichen Hinweise der A.R.S. – Alternate Reality Strategies GmbH sind <a href="https://thadeus-roth.de/impressum.php#datenschutz" target="_blank">hier</a> abrufbar.
            </p>
        </div>
    </div>
</template>
