/**
 * Base model class
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */

export default class BaseModel {

}
