/**
 * Store for temporary data without persistence
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */

import { defaultLocale } from '?/Languages';

// Initial state
const state = () => ({
    flashMessage: null,
    flashMessageReload: null,
    geolocation: null
});

// getters
const getters = {
    flashMessage (state) {
        return state.flashMessage;
    },

    flashMessageReload (state) {
        return state.flashMessage;
    },

    geolocation (state) {
        return state.geolocation;
    }
};

// actions
const actions = {};

// mutations
const mutations = {
    flashMessage (state, flashMessage) {
        state.flashMessage = flashMessage;
    },

    flashMessageReload (state, flashMessageReload) {
        state.flashMessageReload = flashMessageReload;
    },

    geolocation (state, geolocation) {
        state.geolocation = geolocation;
    }
};

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
};
