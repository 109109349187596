/**
 * Prepare languages
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */

import config from '?/Config';
import { mergeObjects } from '?/Composable/Functions';
import de from '?/Language/De';
import deFormal from '?/Language/DeFormal';
import en from '?/Language/En';
import deCustom from '?/Language/DeCustom';
import deFormalCustom from '?/Language/DeFormalCustom';
import enCustom from '?/Language/EnCustom';

export const defaultLocale = config.defaultLanguage || 'de';

export const languages = {
    'de': mergeObjects(de, deCustom),
    'de-formal': mergeObjects(deFormal, deFormalCustom),
    'en': mergeObjects(en, enCustom),
};
