<!--
/**
 * Webapp password template
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { reactive } from 'vue';
    import store from '?/Stores';
    import repository from '?/Composable/Repository';
    import emitter from '?/Composable/Events/Emitter';
    import { t } from '?/Composable/Translation';

    // Data
    const state = reactive({
        submitted: false,
    });

    // Functions
    const changePassword = () => {
        repository.requestPasswordResetPhone()
            .then((response) => {
                // @event password-reset-sent
                emitter.emit('password-reset-sent');

                state.submitted = true;

                return response;
            })
            .catch((error) => {
                store.commit('flashMessage', t('apiError.' + error.code));
            });
    };
</script>

<template>
    <div class="password">
        <p>
            {{ $t('account.change-password-description') }}
        </p>
        <button class="password-button"
                v-if="!state.submitted"
                @click="changePassword()">
            {{ $t('account.change-password') }}
        </button>
        <div class="password-message"
             v-if="state.submitted">
            <p v-html="$t('account.lost-password-reset-successful')"></p>
        </div>
    </div>
</template>
