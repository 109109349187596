<!--
/**
 * Single station template
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { computed, reactive, markRaw } from 'vue';
    import { useRoute } from 'vue-router';
    import store from '?/Stores';
    import Archive from '?/Component/Webapp/StationTypes/Archive';
    import Email from '?/Component/Webapp/StationTypes/Email';
    import Main from '?/Component/Webapp/StationTypes/Main';
    import Station from '?/Component/Webapp/StationTypes/Station';
    import Story from '?/Component/Webapp/StationTypes/Story';
    import Scoreboard from '?/Component/Webapp/StationTypes/Scoreboard';

    // Initialization
    const route = useRoute();

    // Data
    const state = reactive({
        stationSingleView: route.meta.slug,
        station: computed(() => {
            let currentStation = store.getters.station(route.params.id);

            // Only show stations that are live, visible and are in slug-context
            if (
                currentStation &&
                (
                    !currentStation.live ||
                    currentStation.view !== route.meta.slug
                )
            ) {
                return null;
            }

            return currentStation;
        })
    });

    // Functions
    const component = (type) => {
        let component = null;

        switch (type) {
            case 'archive':
                component = markRaw(Archive);

                break;
            case 'email':
                component = markRaw(Email);

                break;
            case 'main':
                component = markRaw(Main);

                break;
            case 'story':
                component = markRaw(Story);

                break;
            case 'scoreboard':
                component = markRaw(Scoreboard);

                break;
            default:
                component = markRaw(Station);
        };

        return component;
    }
</script>

<template>
    <template v-if="state.station">
        <component :is="component(state.stationSingleView)"
                   :station="state.station"
                   :stationVisible="stationVisible"
                   :key="state.station.stationId"></component>
    </template>
    <template v-else>
        <p>
            {{ $t('general.unavailable') }}
        </p>
    </template>
</template>
