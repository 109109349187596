/**
 * Process unix timestamp
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */

import { t } from '?/Composable/Translation';


/**
 * Process unix timestamp
 *
 * @param {int} timestamp Unix timestamp
 *
 * @return {string} Formatted date
 */
export const processTimestamp = (timestamp) => {
    let time = new Date(timestamp);

    return t('tools.time', {
        time: ('0' + time.getHours()).slice(-2) + ':' + ('0' + time.getMinutes()).slice(-2)
    });
};
