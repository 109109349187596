<!--
/**
 * Webapp template for team management
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import config from '?/Config';
    import store from '?/Stores';
    import TeamInvite from '?/Component/Webapp/TeamInvite';
    import TeamJoin from '?/Component/Webapp/TeamJoin';

    const hasTeam = store.getters.hasTeam;
</script>

<template>
    <div id="team" class="team">
        <template v-if="config.allowTeams">
            <template v-if="!hasTeam">
                <h2>{{ $t('team.join-headline') }}</h2>
                <TeamJoin />
                <hr />
            </template>

            <h2>{{ $t('team.invite-headline') }}</h2>
            <TeamInvite />
        </template>
    </div>
</template>
